/* eslint-disable react-hooks/exhaustive-deps */
import * as appSync from '../../api/notification/appsync.js';
import { HeaderWrapper } from './styled';
import { useGetNotificationV2 } from 'api/investor/queries';
import { logout } from 'api/user';
import Amplify from 'aws-amplify';
import Input from 'components/InvestorCommon/InputV2/Input';
import Notification from 'components/InvestorCommon/Notification/Notification';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHook';
import Cookies from 'js-cookie';
import moment from 'moment';
import { routesEnum } from 'pages/Routes';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUser } from 'store/ducks/investor/slice';
import { setType } from 'store/ducks/system/slice';
import { IMAGES } from 'utils/images';

Amplify.configure(appSync.config);

const channel = {
  NOTIFICATION: 'NOTIFICATION',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  LOG_OUT: 'LOG_OUT',
};

interface IRightHeader {
  showAnnouncement: boolean;
  setShowAnnouncement: any;
}
interface IHeader {
  showAnnouncement: boolean;
  setShowAnnouncement: any;
}

const Left: FC = () => {
  return (
    <div className="flex items-center h-full">
      <Link to={routesEnum.home}>
        <img
          alt=""
          src={IMAGES.logo.logo_red}
          width="200px"
          height="48px"
          style={{
            marginRight: '116px',
          }}
        />
      </Link>
      <div style={{ width: '500px' }} className="hidden lg:block">
        <Input placeholder="Search" mode="search" />
      </div>
    </div>
  );
};

const Right: FC<IRightHeader> = (props: IRightHeader) => {
  const dataUser = useAppSelector(getUser);
  const furthestDate = moment(new Date()).subtract(2, 'month').toISOString();
  const { data, refetch } = useGetNotificationV2(furthestDate, dataUser?.id || '', {
    enabled: !!dataUser?.id,
  });
  const { showAnnouncement, setShowAnnouncement } = props;
  const [showNotification, setShowNotification] = useState(false);
  const listNotification = data || [];
  const [totalNotification, setTotalNotification] = useState(0);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleLogout = async (hasRedirect = true) => {
    await logout();
    localStorage.clear();
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    sessionStorage.clear();
    toast.success('Logout successfully!', {
      autoClose: 1000,
    });
    dispatch(setType(''));
    
    if (hasRedirect) {
      const redirectUrl = process.env.WEB_KLDX_NEW_URL;
      const url = `${redirectUrl}/?logout=true`;
      window.location.href = url;
    }
  };

  /** Side effect to detect when logout from KLDX new */
  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hasLogout = urlParams.get('logout') === 'true';
    if (hasLogout) {
      handleLogout(false);
      history.push(routesEnum.investorLoginV2);
    }
  }, []);

  useEffect(() => {
    const subscription = appSync.subscribe(
      channel.NOTIFICATION,
      ({ data }: any) => {
        data = JSON.parse(String(data));
        if (data.idArray?.includes(dataUser?.id)) {
          toast.success('You have a new notification!', {
            autoClose: 1000,
          });
          refetch();
        }
      },
      undefined
    );

    const announcementSubscription = appSync.subscribe(
      channel.ANNOUNCEMENT,
      ({ data }: any) => {
        data = JSON.parse(String(data));
        if (data.idArray?.includes(dataUser?.id)) {
          toast.success('You have a new announcement!', {
            autoClose: 1000,
          });
        }
      },
      undefined
    );
    const userBeUnactive = appSync.subscribe(
      channel.LOG_OUT,
      ({ data }: any) => {
        data = JSON.parse(String(data));
        if (data?.ids?.includes(dataUser?.id)) {
          handleLogout();
        }
      },
      undefined
    );
    return () => {
      userBeUnactive.unsubscribe();
      subscription.unsubscribe();
      announcementSubscription.unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (Array.isArray(data)) {
      const listTotalNotiUnread = data.filter((item) => item.noti_status);
      setTotalNotification(listTotalNotiUnread?.length);
    }
  }, [data]);
  return (
    <div className="flex items-center h-full">
      <div
        className="cursor-pointer relative"
        style={{
          marginRight: '23px',
        }}
        onClick={() => {
          setShowAnnouncement(!showAnnouncement);
          setShowNotification(false);
        }}
      >
        <img alt="" src={IMAGES.header.news} />
        <span className="absolute top-0 right-0 inline-block w-2 h-2 transform translate-x-1/2 -translate-y-1/2 bg-orange-600 rounded-full"></span>
      </div>
      <div
        className="cursor-pointer relative"
        style={{
          marginRight: '23px',
        }}
      >
        <div
          onClick={() => {
            setShowNotification(!showNotification);
            setShowAnnouncement(false);
          }}
        >
          <img alt="" src={IMAGES.header.notification} />
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-orange-100 transform translate-x-1/2 -translate-y-1/2 bg-orange-600 rounded-full">
            {totalNotification}
          </span>
        </div>
        {showNotification && (
          <div className="absolute" style={{ right: '0px', top: '40px' }}>
            <Notification
              listNotification={listNotification}
              refetch={refetch}
              setShowNotification={() => setShowNotification(!showNotification)}
            />
          </div>
        )}
      </div>
      <div className="cursor-pointer">
        <img
          alt=""
          src={IMAGES.header.avatar}
          className="rounded-full"
          style={{
            width: '43px',
            height: '43px',
          }}
        />
      </div>
    </div>
  );
};

const Header: FC<IHeader> = (props: IHeader) => {
  const { showAnnouncement, setShowAnnouncement } = props;
  const dataUser = useAppSelector(getUser);
  return (
    <HeaderWrapper className="w-full fixed top-0 md:flex hidden flex-row items-center justify-between bg-white shadow">
      <Left />
      {dataUser && <Right showAnnouncement={showAnnouncement} setShowAnnouncement={setShowAnnouncement} />}
    </HeaderWrapper>
  );
};

export default Header;
