import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MqttClient } from 'mqtt';
import { RootState } from 'store';

export type Nullable<T> = T | null;

export interface IMqttClientError {
  name: string;
  message: string;
  stack?: string;
}

export interface IMqttClientStore {
  client: any;
  error: Nullable<IMqttClientError>;
}

const initialState: IMqttClientStore = {
  client: null,
  error: null,
};

export const mqttSlice = createSlice({
  name: 'mqtt',
  initialState,
  reducers: {
    setMqttClient: (state, action: PayloadAction<IMqttClientStore>) => {
      return {
        ...state,
        client: action.payload.client,
        error: action.payload.error,
      };
    },
  },
});

export const getMqttClient = (state: RootState): Nullable<MqttClient> => state.mqttConnection.client;
export const getMqttError = (state: RootState) => state.mqttConnection.error;

export const { setMqttClient } = mqttSlice.actions;

export default mqttSlice.reducer;
