import Title from 'components/InvestorCommon/Text/Title';
import { ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 6rem;
  align-items: stretch;
  justify-content: center;
  font-size: 3rem;
`;

function PageNotFound(): ReactElement {
  return (
    <Wrapper>
      <Title level={3}>Sorry, You are not authorized to access this page or cannot find this page.</Title>
    </Wrapper>
  );
}

export default PageNotFound;
