import { ITitleProps, ITextProps } from './type';
import styled from 'styled-components';

const Title = styled.div<ITitleProps>`
  letter-spacing: 0px;
  color: #2d3436;
  opacity: 1;
  ${(props) =>
    props.isLink &&
    `
      color: #2E3192;
    `}

  ${(props) =>
    props.weight &&
    `
        font-weight: ${props.weight} !important;
      `}

      
  ${(props) =>
    props.color &&
    `
      color: ${props.color};
    `}
    
      font: normal normal 900 30px/36px Lato;
  ${(props) =>
    props.level == 1 &&
    `
      font: normal normal 900 30px/36px Lato;
    `}
  ${(props) =>
    props.level == 2 &&
    `
      font: normal normal 900 25px/30px Lato;
    `}
  ${(props) =>
    props.level == 3 &&
    `
      font: normal normal 900 20px/28px Lato;
    `}
  ${(props) =>
    props.level == 4 &&
    `
      font: normal normal bold 20px/24px Lato;
    `}
  ${(props) =>
    props.level == 5 &&
    `
      font: normal normal bold 18px/25px Lato;
    `}
  ${(props) =>
    props.level == 6 &&
    `
      font: normal normal bold 18px/22px Lato;
    `}
  ${(props) =>
    props.level == 7 &&
    `
      font: normal normal bold 16px/19px Lato;
    `}
  ${(props) =>
    props.level == 8 &&
    `
      font: normal normal bold 14px/18px Lato;
    `}
  ${(props) =>
    props.level == 9 &&
    `
      font: normal normal bold 14px/16px Lato;
    `}
  ${(props) =>
    props.level == 10 &&
    `
      font: normal normal bold 12px/14px Lato;
    `}
    
  @media (min-width: 640px) {
    font: normal normal 900 34px/41px Lato;
    ${(props) =>
      props.level == 1 &&
      `
      font: normal normal 900 34px/41px Lato;
    `}
    ${(props) =>
      props.level == 2 &&
      `
      font: normal normal 900 30px/36px Lato;
    `}
  ${(props) =>
      props.level == 3 &&
      `
      font: normal normal 900 25px/30px Lato;
    `}
  ${(props) =>
      props.level == 2 &&
      `
      font: normal normal 900 30px/36px Lato;
    `}
  ${(props) =>
      props.level == 3 &&
      `
      font: normal normal 900 25px/30px Lato;
    `}
    ${(props) =>
      props.level == 2 &&
      `
      font: normal normal 900 30px/36px Lato;
    `}
  ${(props) =>
      props.level == 3 &&
      `
      font: normal normal 900 25px/30px Lato;
    `}
  ${(props) =>
      props.level == 4 &&
      `
      font: normal normal 900 20px/28px Lato;
    `}
  ${(props) =>
      props.level == 5 &&
      `
      font: normal normal bold 20px/24px Lato;
    `}
  ${(props) =>
      props.level == 6 &&
      `
      font: normal normal bold 18px/25px Lato;
    `}
  ${(props) =>
      props.level == 7 &&
      `
      font: normal normal bold 18px/22px Lato;
    `}
  ${(props) =>
      props.level == 8 &&
      `
      font: normal normal bold 16px/19px Lato;
    `}
  ${(props) =>
      props.level == 9 &&
      `
      font: normal normal bold 14px/18px Lato;
    `}
  ${(props) =>
      props.level == 10 &&
      `
      font: normal normal bold 14px/16px Lato;
    `}
  }

  @media (min-width: 1024px) {
    font: normal normal 900 40px/48px Lato;
    ${(props) =>
      props.level == 1 &&
      `
      font: normal normal 900 40px/48px Lato;
    `}
    ${(props) =>
      props.level == 2 &&
      `
      font: normal normal 900 34px/41px Lato;
    `}
    ${(props) =>
      props.level == 3 &&
      `
        font: normal normal 900 26px/32px Lato;
     `}
    ${(props) =>
      props.level == 4 &&
      `
        font: normal normal 900 24px/30px Lato;
      `}
  ${(props) =>
      props.level == 5 &&
      `
      font: normal normal 900 20px/28px Lato;
    `}
  ${(props) =>
      props.level == 6 &&
      `
      font: normal normal bold 20px/24px Lato;
    `}
  ${(props) =>
      props.level == 7 &&
      `
      font: normal normal bold 18px/25px Lato;
    `}
  ${(props) =>
      props.level == 8 &&
      `
      font: normal normal bold 18px/22px Lato;
    `}
  ${(props) =>
      props.level == 9 &&
      `
      font: normal normal bold 16px/19px Lato;
    `}
  ${(props) =>
      props.level == 10 &&
      `
      font: normal normal bold 14px/18px Lato;
    `}
  }

  @media (min-width: 1536px) {
    font: normal normal 900 48px/57px Lato;
    ${(props) =>
      props.level == 1 &&
      `
      font: normal normal 900 48px/57px Lato;
    `}
    ${(props) =>
      props.level == 2 &&
      `
      font: normal normal 900 40px/48px Lato;
    `}
  ${(props) =>
      props.level == 3 &&
      `
      font: normal normal 900 30px/38px Lato;
    `}
  ${(props) =>
      props.level == 4 &&
      `
      font: normal normal 900 28px/34px Lato;
    `}
  ${(props) =>
      props.level == 5 &&
      `
      font: normal normal 900 25px/30px Lato;
    `}
  ${(props) =>
      props.level == 6 &&
      `
      font: normal normal 900 20px/28px Lato;
    `}
  ${(props) =>
      props.level == 7 &&
      `
      font: normal normal bold 20px/24px Lato;
    `}
  ${(props) =>
      props.level == 8 &&
      `
      font: normal normal bold 18px/25px Lato;
    `}
  ${(props) =>
      props.level == 9 &&
      `
      font: normal normal bold 18px/22px Lato;
    `}
  ${(props) =>
      props.level == 10 &&
      `
      font: normal normal bold 16px/19px Lato;
    `}
      ${(props) =>
      props.level == 11 &&
      `
      font: normal normal 900 22px/27px Lato;
    `}
  }
`;

const Paragraph = styled.div<ITextProps>`
  text-align: center;
  letter-spacing: 0px;
  color: #2d3436;
  opacity: 1;
  white-space: pre-wrap;
  ${(props) =>
    `
    text-align: ${props.textAlign};
    `}
  ${(props) =>
    props.isLink &&
    `
      color: #2E3192;
    `}
  ${(props) =>
    props.color &&
    `
      color: ${props.color};
    `}

      font: normal normal normal 18px/25px Lato;
  ${(props) =>
    props.level == 1 &&
    `
      font: normal normal normal 18px/25px Lato;
    `}
  ${(props) =>
    props.level == 2 &&
    `
      font: normal normal normal 18px/22px lato;	
    `}
  ${(props) =>
    props.level == 3 &&
    `
      font: normal normal normal 16px/20px lato;	
    `}
  ${(props) =>
    props.level == 4 &&
    `
    font: normal normal normal 14px/18px Lato;
    `}
  ${(props) =>
    props.level == 5 &&
    `
    font: normal normal normal 12px/14px Lato;
    `}

  @media (min-width: 640px) {
    font: normal normal normal 20px/24px Lato;
    ${(props) =>
      props.level == 1 &&
      `
      font: normal normal normal 20px/24px Lato;
    `}
    ${(props) =>
      props.level == 2 &&
      `
      font: normal normal normal 18px/25px Lato;
    `}
  ${(props) =>
      props.level == 3 &&
      `
      font: normal normal normal 18px/22px lato;	
    `}
  ${(props) =>
      props.level == 4 &&
      `
      font: normal normal normal 16px/20px lato;	
    `}
  ${(props) =>
      props.level == 5 &&
      `
    font: normal normal normal 14px/18px Lato;
    `}
  }

  @media (min-width: 1024px) {
    font: normal normal normal 20px/28px Lato;
    ${(props) =>
      props.level == 1 &&
      `
      font: normal normal normal 20px/28px Lato;
    `}
    ${(props) =>
      props.level == 2 &&
      `
      font: normal normal normal 20px/24px Lato;
    `}
  ${(props) =>
      props.level == 3 &&
      `
      font: normal normal normal 18px/25px Lato;
    `}
  ${(props) =>
      props.level == 4 &&
      `
      font: normal normal normal 18px/22px lato;	
    `}
  ${(props) =>
      props.level == 5 &&
      `
      font: normal normal normal 16px/20px lato;	
    `}
  }

  @media (min-width: 1536px) {
    font: normal normal normal 25px/30px Lato;
    ${(props) =>
      props.level == 1 &&
      `
      font: normal normal normal 25px/30px Lato;
    `}
    ${(props) =>
      props.level == 2 &&
      `
      font: normal normal normal 20px/28px Lato;
    `}
  ${(props) =>
      props.level == 3 &&
      `
      font: normal normal normal 20px/24px Lato;
    `}
  ${(props) =>
      props.level == 4 &&
      `
      font: normal normal normal 18px/25px Lato;
    `}
  ${(props) =>
      props.level == 5 &&
      `
      font: normal normal normal 18px/22px Lato;	
    `}
  }
`;

const Text = styled.div<ITextProps>`
  text-align: left;
  letter-spacing: 0px;
  color: #2d3436;
  opacity: 1;
  ${(props) =>
    props.isLink &&
    `
      color: #2E3192;
    `}
  ${(props) =>
    props.color &&
    `
      color: ${props.color};
    `}
    ${(props) =>
    props.weight &&
    `
      font-weight: ${props.weight} !important;
    `}

    ${(props) =>
    props.size &&
    `
      font-size: ${props.size}px !important;
    `}
  font: normal normal normal 16px/20px Lato;
  ${(props) =>
    props.level == 1 &&
    `
      font: normal normal normal 16px/20px Lato;
    `}
  ${(props) =>
    props.level == 1.1 &&
    `
      font: normal normal bold 18px/25px Lato;
    `}
  ${(props) =>
    props.level == 2 &&
    `
      font: normal normal normal 18px/22px lato;	
    `}
  ${(props) =>
    props.level == 2.1 &&
    `
        font: normal normal bold 18px/22px lato;	
      `}
  ${(props) =>
    props.level == 3 &&
    `
      font: normal normal normal 16px/20px lato;	
    `}
    ${(props) =>
    props.level == 3.1 &&
    `
        font: normal normal bold 16px/20px lato;	
      `}
  ${(props) =>
    props.level == 4 &&
    `
    font: normal normal normal 16px/18px Lato;
    `}
  ${(props) =>
    props.level == 5 &&
    `
    font: normal normal normal 14px/18px Lato;
    `}
    ${(props) =>
    props.level == 5.1 &&
    `
      font: normal normal bold 14px/18px Lato;
      `}
  ${(props) =>
    props.level == 6 &&
    `
      font: normal normal normal 12px/16px lato;	
    `}

  @media (min-width: 640px) {
    font: normal normal normal 20px/24px Lato;
    ${(props) =>
      props.level == 1 &&
      `
      font: normal normal normal 20px/24px Lato;
    `}
    ${(props) =>
      props.level == 1.1 &&
      `
      font: normal normal bold 20px/24px Lato;
    `}
    ${(props) =>
      props.level == 2 &&
      `
      font: normal normal normal 18px/25px Lato;
    `}
    ${(props) =>
      props.level == 2.1 &&
      `
      font: normal normal bold 18px/25px Lato;
    `}
  ${(props) =>
      props.level == 3 &&
      `
      font: normal normal normal 18px/22px lato;	
    `}
    ${(props) =>
      props.level == 3.1 &&
      `
      font: normal normal bold 18px/22px lato;	
    `}
  ${(props) =>
      props.level == 4 &&
      `
      font: normal normal normal 16px/19px lato;	
    `}
  ${(props) =>
      props.level == 5 &&
      `
    font: normal normal normal 16px/18px Lato;
    `}
    ${(props) =>
      props.level == 5.1 &&
      `
    font: normal normal bold 16px/18px Lato;
    `}
  ${(props) =>
      props.level == 6 &&
      `
      font: normal normal normal 14px/18px lato;	
    `}
  }

  @media (min-width: 1024px) {
    font: normal normal normal 20px/28px Lato;
    ${(props) =>
      props.level == 1 &&
      `
      font: normal normal normal 20px/28px Lato;
    `}
    ${(props) =>
      props.level == 1.1 &&
      `
      font: normal normal bold 20px/28px Lato;
    `}
    ${(props) =>
      props.level == 2 &&
      `
      font: normal normal normal 20px/24px Lato;
    `}
  ${(props) =>
      props.level == 2.1 &&
      `
      font: normal normal bold 20px/24px Lato;
    `}
  ${(props) =>
      props.level == 3 &&
      `
      font: normal normal normal 16px/22px Lato;
    `}
    ${(props) =>
      props.level == 3.1 &&
      `
      font: normal normal bold 16px/22px Lato;
    `}
  ${(props) =>
      props.level == 4 &&
      `
      font: normal normal normal 18px/22px lato;	
    `}
  ${(props) =>
      props.level == 5 &&
      `
      font: normal normal normal 16px/19px lato;	
    `}
    ${(props) =>
      props.level == 5.1 &&
      `
      font: normal normal bold 16px/19px lato;	
    `}
  ${(props) =>
      props.level == 6 &&
      `
      font: normal normal normal 16px/18px lato;	
    `}
  }

  @media (min-width: 1536px) {
    font: normal normal normal 25px/30px Lato;
    ${(props) =>
      props.level == 1 &&
      `
      font: normal normal normal 25px/30px Lato;
    `}
    ${(props) =>
      props.level == 1.1 &&
      `
      font: normal normal bold 25px/30px Lato;
    `}
    ${(props) =>
      props.level == 2 &&
      `
      font: normal normal normal 20px/28px Lato;
    `}
    ${(props) =>
      props.level == 2.1 &&
      `
      font: normal normal bold 20px/28px Lato;
    `}
  ${(props) =>
      props.level == 3 &&
      `
      font: normal normal normal 20px/24px Lato;
    `}
    ${(props) =>
      props.level == 3.1 &&
      `
      font: normal normal bold 20px/24px Lato;
    `}
  ${(props) =>
      props.level == 4 &&
      `
      font: normal normal normal 18px/25px Lato;
    `}
  ${(props) =>
      props.level == 5 &&
      `
      font: normal normal normal 18px/22px Lato;	
    `}
    ${(props) =>
      props.level == 5.1 &&
      `
      font: normal normal bold 18px/22px Lato;	
    `}
  ${(props) =>
      props.level == 6 &&
      `
      font: normal normal normal 16px/19px Lato;
    `}
  }
`;

export default {
  Title,
  Paragraph,
  Text,
};
