import { formatNumber, hex2int } from './formatter';
import { isWithinInterval } from 'date-fns';
import Cookies from 'js-cookie';
import _ from 'lodash';
import moment, { unitOfTime } from 'moment';
import { DateTimeField } from 'pages/InvestorPage/Transactions/components/components/utils/types';
import {
  formatOptions,
  IFormatDecimalProps,
  IFormatPrecision,
  IFormatPrice,
  IOption,
  PrecisionOptions,
  TOKENDEFAULT,
} from 'types/common';
import { NOT_NUMBER_REGEX } from './regex';
import { TOKEN_DEFAULT } from './constant';

export const tokenQtyMultiplier = () => (process.env.WEB_COMPANY_NAME === 'BRAND1' ? 1000 : 1);

// convert empty string or string number (100,000) to number (100000)
export const convertTextNumberToValue = (originalValue: string | number) => {
  if (typeof originalValue === 'number') {
    return originalValue;
  }

  const convertValue = Number(String(originalValue).replace(/,/g, ''));
  return Number.isNaN(convertValue) ? 0 : convertValue;
};

export const totalTokenQty = (selectedTokens: Array<any>, showUnit: boolean = false) => {
  const total = selectedTokens.reduce((result, token) => {
    return result + hex2int(token.currentQty);
  }, 0);

  const divider = showUnit ? 1000 : 1;
  return total / divider;
};

export const ipfsLink = (hash: string): string => {
  return `https://gateway.pinata.cloud/ipfs/${hash}`;
};

export function getIpfsHash(value: string): string {
  const [ipfsHash] = value?.split(',') ?? [];
  return ipfsHash;
}

export function getFileName(value: string): string {
  const [, filename] = value?.split(',') ?? [];
  return filename;
}

export const toUTCDate = (date: Date | number | string) => new Date(date).toUTCString();
export const unixToUTCDate = (timestamp: number) => new Date(timestamp * 1000).toUTCString();
// TODO: support format date from timestamp

export const isValidDate = (
  selectedRange: {
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
  },
  timestamp: number
) => {
  if (!selectedRange.endDate || !selectedRange.startDate) {
    return true;
  }

  return isWithinInterval(new Date(unixToUTCDate(timestamp)), {
    start: new Date(toUTCDate(selectedRange.startDate)),
    end: new Date(toUTCDate(selectedRange.endDate)),
  });
};

export const getPaginatedTransactions = ({
  transactions,
  page,
  limit,
}: {
  transactions: Record<string, any>;
  page: number;
  limit: number | string;
}) => {
  if (Number.isNaN(Number(limit))) {
    return Object.keys(transactions).reduce((result: Record<string, any>, key) => {
      const res = { ...result };
      res[key] = transactions[key];

      return res;
    }, {});
  }

  const from = (page - 1) * Number(limit);
  const to = page * Number(limit);

  return Object.keys(transactions)
    .slice(from, to)
    .reduce((result: Record<string, any>, key) => {
      const res = { ...result };
      res[key] = transactions[key];

      return res;
    }, {});
};

export function groupEventsBy(records: Array<any>, key: string, singleChild = false) {
  const transactions = records
    .sort(({ blockNumber }: Record<string, any>, { blockNumber: nextBlockNumber }: Record<string, any>) => {
      if (blockNumber > nextBlockNumber) return -1;
      if (blockNumber < nextBlockNumber) return +1;
      return 0;
    })
    .reduce((result, record) => {
      const res = { ...result };
      const recordKey = record[key];

      if (!singleChild) {
        res[recordKey] = res[recordKey] || [];
        res[recordKey].push(record);
      } else {
        res[recordKey] = record;
      }

      return res;
    }, {});

  return transactions;
}
export function arrayToObject(records: Array<any>, key: string) {
  const transactions = records.reduce((result, record) => {
    const res = { ...result };
    if (record?.[key]) {
      const recordKey = record[key];
      res[recordKey] = record;
    }

    return res;
  }, {});

  return transactions;
}

export function wrapString(str: string) {
  if (!str || str === '') return '';

  let convertedStr = '';
  const toPosition = Math.floor(str.length / 2);
  convertedStr += str.substring(0, toPosition / 3);
  convertedStr += '.'.repeat(3);
  convertedStr += str.substring(toPosition + toPosition / 2, str.length);
  return convertedStr;
}

export function clipboardCopy(text: string) {
  let success = false;

  if (typeof window !== 'undefined') {
    // Use the Async Clipboard API when available. Requires a secure browsing
    // context (i.e. HTTPS)
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(text).catch(function (err) {
        throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
      });
    }

    // ...Otherwise, use document.execCommand() fallback

    // Put the text to copy into a <span>
    const span = document.createElement('span');
    span.textContent = text;

    // Preserve consecutive spaces and newlines
    span.style.whiteSpace = 'pre';

    // Add the <span> to the page
    document.body.appendChild(span);

    // Make a selection object representing the range of text selected by the user
    const selection = window.getSelection();
    const range = window.document.createRange();

    if (selection) {
      selection.removeAllRanges();
      range.selectNode(span);
      selection.addRange(range);

      // Copy text to the clipboard
      try {
        success = window.document.execCommand('copy');
      } catch (err) {}

      // Cleanup
      selection.removeAllRanges();
      window.document.body.removeChild(span);
    }
  }

  return success
    ? Promise.resolve()
    : Promise.reject(new DOMException('The request is not allowed', 'NotAllowedError'));
}

export const removeEmptyValue = (values?: Array<string>) =>
  values?.filter((value: string | null | undefined) => !!value);

/**
 * Convert unit and show with formatted
 * @param val int
 * @param showUnit boolean
 */
export const convertFormattedUnit = (val: number, showUnit: boolean) => {
  if (showUnit) {
    return `${formatNumber(val / 1000, 0)} MtC02`;
  }

  return formatNumber(val, 0);
};

export const convertArrayToURParams = (key: string, values: Array<any>) => {
  return values.map((value) => `${key}[]=${value}`).join('&');
};

export const getRoleName = (accountType: string, parentId?: number) => {
  switch (accountType) {
    case 'corp-trader':
      return {
        name: 'Corporate Trader',
        short: 'Trader',
      };

    case 'corp-admin':
      return {
        name: 'Corporate Admin',
        short: parentId ? 'Admin' : 'Root',
      };

    case 'corp-monitor':
      return {
        name: 'Corporate Monitor',
        short: 'Monitor',
      };

    default:
      return {
        name: '',
        short: '',
      };
  }
};

export const decryptData = (value: string) => (value.startsWith('U2Fsd') ? revealMessage(value) : value);

/**
 * Gets SC batch and returns decrypted metadata
 * @param batch smart contract raw batch
 * @returns decrypted project metadata
 */
export const getProjectData = (batch: any) => {
  const projectData: Record<string, any> = {};

  batch?.metaKeys.forEach((key: string, index: number) => {
    const value = batch.metaValues[index];
    const msg = decryptData(value);
    projectData[key] = msg;
  });

  return projectData;
};

export const groupBatchesByBatchId = (batches: Array<any>, tokens: Array<any>) =>
  batches?.reduce((projects: Record<string, any>, batch: Record<string, any>) => {
    const batchId = hex2int(batch.batchId);
    const batchSTs = tokens.filter(
      (token: { batchId: { _hex: string | number } }) => hex2int(token.batchId) === batchId
    );

    const stIds = batchSTs.map((token: { stId: { _hex: string | number } }) => hex2int(token.stId));
    const qty = batchSTs.reduce((sum: number, st: Record<string, any>) => {
      sum += hex2int(st.currentQty);
      return sum;
    }, 0);

    const currentQty = qty / tokenQtyMultiplier();
    const createdAt = batch.mintedTimestamp ? unixToUTCDate(batch.mintedTimestamp) : '';
    const project: Record<string, any> = batch.metaData;

    if (projects[batchId]) {
      return {
        ...projects,
        [batchId]: {
          ...projects[batchId],
          stIds: [...projects[batchId].stIds, ...stIds],
        },
        currentQty: projects[batchId].currentQty + qty,
      };
    }

    return {
      ...projects,
      [batchId]: {
        batchId,
        tokenTypeId: hex2int(batch.tokTypeId),
        stIds,
        project: {
          ...project,
          vintage:
            project.DATE_VINTAGE_START?.substr(
              project.DATE_VINTAGE_START.length - 4,
              project.DATE_VINTAGE_START.length - 1
            ) ?? '',
        },
        currentQty,
        createdAt,
      },
    };
  }, {});

export const transformArray = (dataArray: any) => {
  return Array.isArray(dataArray) ? dataArray : [];
};

const { HIDE_MESSAGE_SECRET_KEY } = process.env;
export function revealMessage(cipherText: string): string {
  try {
    // if (HIDE_MESSAGE_SECRET_KEY) {
    //   const bytes = AES.decrypt(cipherText, HIDE_MESSAGE_SECRET_KEY);
    //   return bytes.toString(EncUtf8);
    // }
    return cipherText;
  } catch (err) {
    return cipherText;
  }
}

export function capitalizeFirstLetter(str = '') {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const handlerRedirectURL = (keyTabURL: string, valueTabURL: string) => {
  const accessToken = Cookies.get('token');
  const refreshToken = Cookies.get('refreshToken');

  const redirectUrl = process.env.WEB_KLDX_NEW_URL;
  const url = `${redirectUrl}/investors/trading-platform?${keyTabURL}=${valueTabURL}&token=${accessToken}&refreshToken=${refreshToken}`;
  window.location.href = url;
};

export const handleFormatDecimal = ({ formatOption, inputNumber }: IFormatDecimalProps) => {
  const _formatOptions = Number(formatOption);
  switch (_formatOptions) {
    case formatOptions.zero:
      return Math.ceil(inputNumber);

    case formatOptions.ten:
      return Math.ceil(inputNumber / 10) * 10;

    case formatOptions.fifty:
      return Math.ceil(inputNumber / 50) * 50;

    default:
      return inputNumber.toFixed(formatOption);
  }
};

export const handleFormatNumber = (arg: number | bigint): string => {
  return new Intl.NumberFormat('en-US').format(arg);
};

export const handleFormatPrice = ({ inputNumber, formatOption }: IFormatPrice) => {
  const formattedDecimalPrice = handleFormatDecimal({
    inputNumber: inputNumber || 0,
    formatOption,
  });

  const splitArray = formattedDecimalPrice.toString().split('.');

  const numberPart = Number(splitArray[0]);
  const decimalPart = splitArray[1];

  const formattedNumber = handleFormatNumber(numberPart);

  return decimalPart ? `${formattedNumber}.${decimalPart}` : formattedNumber;
};

export const handleFormatPrecision = ({ inputNumber = 0, formatOption }: IFormatPrecision) => {
  if (formatOption === PrecisionOptions.decimalZero) {
    const formattedPrice = Math.round(inputNumber);

    const formattedNumber = formatNumber(Number(formattedPrice));

    return formattedNumber;
  }

  return handleFormatPrice({ inputNumber, formatOption });
};

export const formatISODateTime = (date: Date) => {
  return new Date(moment(date).toISOString())?.toISOString();
};

export const generateISODateTime = (dateTime: string | Date, key: DateTimeField) => {
  if (!dateTime) return '';

  if (key === DateTimeField.FROM) {
    const formatFrom = new Date(dateTime as Date);
    formatFrom.setHours(0, 0, 0, 0);
    return formatISODateTime(formatFrom);
  }

  const formatTo = new Date(dateTime as Date);
  formatTo.setHours(23, 59, 59, 999);
  return formatISODateTime(formatTo);
};

export const handleGenerateQueryFromArr = (arr: IOption[]): string => {
  return arr.map((option) => option.value).join(', ');
};

export const handleRemoveEmptyMember = <T extends Record<string, any>>(obj: T) => {
  const copiedObj = { ...obj };

  Object.entries(copiedObj).forEach((value) => {
    if (typeof value[1] === 'boolean') return;

    if ((typeof value[1] !== 'number' && _.isEmpty(value[1])) || Number.isNaN(value[1])) {
      const key = value[0] as keyof typeof copiedObj;
      delete copiedObj[key];
    }
  });

  return copiedObj;
};

export const generateDiffBetweenDate = (prevDate: Date | string, nextDate: Date | string, type: unitOfTime.Diff) => {
  const fromDate = moment(prevDate);
  const toDate = moment(nextDate);
  return toDate.diff(fromDate, type);
};

export const isInRangeDate = (startDate: string | Date, endDate: string | Date) => {
  return new Date(startDate).getTime() <= new Date(endDate).getTime();
};

export const handleFormatDate = (date: string | Date, format: string) => {
  return date ? moment(date).format(format) : '-';
};

export const renderValue = (checkValue: boolean, value: string, isToken: boolean) => {
  if (checkValue || !value) {
    return '-';
  }
  return isToken ? `${TOKENDEFAULT.RM} ${formatNumber(Number(value) || 0)}` : handleFormatNumber(Number(value) || 0);
};

export const handleFormatMoney = (money: string) => {
  const numberPart = money.replaceAll(NOT_NUMBER_REGEX, "")
  const formattedNumber = handleFormatPrice({ inputNumber: Number(numberPart), formatOption: formatOptions.decimalTwo })

  return `${TOKEN_DEFAULT.RM} ${formattedNumber}`
}
