/* eslint-disable react-hooks/exhaustive-deps */
import * as appSync from '../../api/notification/appsync.js';
import { expandIconPng } from './styled';
import { useGetNotificationV2 } from 'api/investor/queries';
import { logout } from 'api/user';
import Amplify from 'aws-amplify';
import Notification from 'components/InvestorCommon/Notification/Notification';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHook';
import Cookies from 'js-cookie';
import moment from 'moment';
import { routesEnum } from 'pages/Routes';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUser } from 'store/ducks/investor/slice';
import { setType } from 'store/ducks/system/slice';
import { IMAGES } from 'utils/images';

interface IHeaderMobileProps {
  setMenuCollapse: () => void;
  showAnnouncement: boolean;
  setShowAnnouncement: any;
}

interface IRightHeader extends IHeaderMobileProps {}

const Left: FC = () => {
  return (
    <div className="items-start">
      <Link to={routesEnum.home}>
        <img alt="" src={IMAGES.logo.logo_red} className="inline-block h-10" />
      </Link>
    </div>
  );
};

Amplify.configure(appSync.config);

const channel = {
  LOG_OUT: 'LOG_OUT',
};

const Right = ({ setMenuCollapse, setShowAnnouncement, showAnnouncement }: IRightHeader) => {
  const [showNotification, setShowNotification] = useState(false);
  const dataUser = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const furthestDate = moment(new Date()).subtract(2, 'month').toISOString();
  const { data, refetch } = useGetNotificationV2(furthestDate, dataUser?.id || '', {
    enabled: !!dataUser?.id,
  });
  const [totalNotification, setTotalNotification] = useState(0);
  const listNotification = data || [];

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 1000 * 5);
    return () => clearInterval(intervalId);
  }, [refetch, data]);

  const handleLogout = async (hasRedirect = true) => {
    await logout();
    localStorage.clear();
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    sessionStorage.clear();
    toast.success('Logout successfully!', {
      autoClose: 1000,
    });
    dispatch(setType(''));

    if (hasRedirect) {
      const redirectUrl = process.env.WEB_KLDX_NEW_URL;
      const url = `${redirectUrl}/?logout=true`;
      window.location.href = url;
    }
  };

  /** Side effect to detect when logout from KLDX new */
  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hasLogout = urlParams.get('logout') === 'true';
    if (hasLogout) {
      handleLogout(false);
      history.push(routesEnum.investorLoginV2);
    }
  }, []);

  useEffect(() => {
    const userBeUnactive = appSync.subscribe(
      channel.LOG_OUT,
      ({ data }: any) => {
        data = JSON.parse(String(data));
        if (data?.ids?.includes(dataUser?.id)) {
          handleLogout();
        }
      },
      undefined
    );
    return () => {
      userBeUnactive.unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (Array.isArray(data)) {
      const listTotalNotiUnread = data.filter((item) => item.noti_status);
      setTotalNotification(listTotalNotiUnread?.length);
    }
  }, [data]);
  return (
    <div className="flex flex-row gap-6 items-center z-50">
      <div className="cursor-pointer relative" onClick={setMenuCollapse}>
        <img src={expandIconPng} alt="" />
      </div>
      <div
        className="cursor-pointer relative"
        onClick={() => {
          setShowAnnouncement(!showAnnouncement);
          setShowNotification(false);
        }}
      >
        <img src={IMAGES.header.news} alt="" />
        <span className="absolute top-0 right-0 inline-block w-2 h-2 transform translate-x-2 -translate-y-2 bg-orange-600 rounded-full"></span>
      </div>
      <div
        className="cursor-pointer relative"
        onClick={() => {
          setShowNotification(!showNotification);
          setShowAnnouncement(false);
        }}
      >
        <img src={IMAGES.header.notification} alt="" />
        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-orange-100 transform translate-x-5 -translate-y-4 bg-orange-600 rounded-full">
          {totalNotification}
        </span>
        {showNotification && (
          <div className="absolute" style={{ right: '-10px', top: '40px' }}>
            <Notification
              listNotification={listNotification}
              refetch={refetch}
              setShowNotification={() => setShowNotification(!showNotification)}
            />
          </div>
        )}
      </div>
      <div className="cursor-pointer flex-grow">
        <img
          alt=""
          src={IMAGES.header.black_logout}
          width="24px"
          height="24px"
          onClick={() => {
            dispatch(setType(''));
            localStorage.clear();
            Cookies.remove('token');
            sessionStorage.clear();
            history.push(routesEnum.home);
            toast.success('Logout successfully!', {
              autoClose: 1000,
            });
          }}
        />
      </div>
    </div>
  );
};

const HeaderMobile = ({ setMenuCollapse, setShowAnnouncement, showAnnouncement }: IHeaderMobileProps) => {
  return (
    <div
      style={{
        maxWidth: '100%',
      }}
    >
      <div
        className="shadow-lg flex flex-row pl-6 sm:pl-10 pr-4 sm:pr-8 py-6 sm:py-10 items-center gap-5 justify-between bg-white fixed top-0 w-full z-50"
        style={{
          maxWidth: '100%',
        }}
      >
        <Left />
        <Right
          setMenuCollapse={setMenuCollapse}
          setShowAnnouncement={setShowAnnouncement}
          showAnnouncement={showAnnouncement}
        />
      </div>
      <div className="w-full h-20"></div>
    </div>
  );
};

export default HeaderMobile;
