import S from './styled';
import { ITextProps } from './type';
import React, { ComponentProps, forwardRef } from 'react';

type Props = Omit<ComponentProps<any>, ''> & ITextProps;

const Text = forwardRef<HTMLButtonElement, Props>(
  ({ color, children, height, onClick, radius, width, className, text, size, disabled, level, ...props }) => {
    return (
      <S.Text
        onClick={onClick}
        text={text}
        className={`${className} inline-flex items-center`}
        size={size}
        disabled={disabled}
        level={level}
        color={color}
        {...props}
      >
        {children}
      </S.Text>
    );
  }
);

export default Text;
