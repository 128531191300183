import { useAppDispatch } from 'hooks/reduxHook';
import { IClientOptions, MqttClient } from 'mqtt';
import { useEffect, useRef } from 'react';
import { mqttService } from 'services';
import { setMqttClient } from 'store/ducks/mqtt/slice';

const useMqtt = (options?: IClientOptions) => {
  const dispatch = useAppDispatch();
  const _mqttListener = useRef<MqttClient | undefined>();

  useEffect(() => {
    const handleError = (error: Error) => {
      const payload = {
        client: null,
        error,
      };
      dispatch(setMqttClient(payload));
    };

    (async () => {
      const url =
        process.env.NODE_ENV !== 'production'
          ? process.env.WEB_KLDX_MQTT_URL_DEV
          : process.env.WEB_KLDX_MQTT_URL_PRODUCTION;

      try {
        const client = await mqttService.getClient(url as string, options, handleError);
        if (!client || !client?.connected) return;
        const payload = {
          client: client,
          error: null,
        };

        _mqttListener.current = payload.client;
        dispatch(setMqttClient(payload));
      } catch (err: any) {
        handleError(err);
      }
    })();
  }, [dispatch, options]);

  /** end connection when un-mount */
  useEffect(() => {
    return () => {
      if (_mqttListener.current) {
        const payload = {
          client: null,
          error: null,
        };
        dispatch(setMqttClient(payload));
        mqttService.closeConnection(_mqttListener.current);
      }
    };
  }, [dispatch]);

  return null;
};

export default useMqtt;
