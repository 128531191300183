import * as React from 'react';
import styled from 'styled-components';

const LoadingSpin = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .spinner {
    &:after {
      width: 2.88em;
      height: 2.88em;
      top: calc(50% - (2.88em / 2));
      left: calc(50% - (2.88em / 2));
    }
  }
`;

const Loading: React.FC = ({ children }) => {
  return (
    <LoadingSpin>
      <div role="alert" className="w-3 h-3 pb-2 mb-6 spinner" />
      {children}
    </LoadingSpin>
  );
};

export default Loading;
