import { IOption } from "types/common";

export interface IInvestorAccountFooter {
  totalItem: number;
  maxIndexItemInPage: number;
  minIndexItemInPage: number;
  numberPage: number;
  setNumberPage: any;
  totalPage: number;
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface IInvestorAccountParams {
  page: number;
  size: number;
  timeZone: string;
  field: string;
  order: OrderDirection;
  activity: IOption[];
  from: string;
  to: string;
  tokenCode: string;
}

export enum DateTimeField {
  TO = 'TO',
  FROM = 'FROM'
}

export interface IInvestorFilterFormValues {
  tokenCode: string;
  from: string;
  to: string;
  activity: IOption[];
}

export interface IInvestorFilterForm {
  params: IInvestorAccountParams;
  onSetParams: (params: IInvestorAccountParams) => void;
  activityOptions: IOption[]
}