import { IButtonProps } from './type';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Button = styled.button<IButtonProps>`
  outline: 0;
  background: var(--bg-primary-color);
  color: var(--black-color);
  border: 1px solid var(--primary-border-color);
  border-radius: 5px;
  font: normal normal bold 20px/24px Lato;
  &:focus {
    outline: none;
  }
  @media (max-width: 1536px) {
    font: normal normal bold 14px Lato;
  }
  ${(props) =>
    props.rounded === 'l' &&
    `
    border-radius: 5px 0 0 5px;
    `}
  ${(props) =>
    props.width &&
    `
    width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    `
    height: ${props.height};
    `}
  ${(props) =>
    props.rounded === 'l' &&
    `
    border-radius: 5px 0 0 5px;
    `}
  ${(props) =>
    props.rounded === 'r' &&
    `
    border-radius: 0 5px 5px 0;
    `}
  ${(props) =>
    props.rounded === 'none' &&
    `
    border-radius: 0px;
    `}
  ${(props) =>
    props.theme === 'secondary' &&
    `
      background: var(--bg-secondary-color);
      color: var(--white-color);

    `}
  ${(props) =>
    props.theme === 'danger' &&
    `
      border: 0px;
      background: var(--bg-danger-color);
      color: var(--white-color);
    `}
  ${(props) =>
    props.text === 'uppercase' &&
    `
      text-transform: uppercase;
    `}
    ${(props) =>
    props.text === 'lowercase' &&
    `
      text-transform: lowercase;
    `}
    ${(props) =>
    props.size === 'middle' &&
    `
      font-size: 20px;
    `}
    ${(props) =>
    props.wrap === 'full' &&
    `
      width: 100%;
      text-align: center;
    `}
    ${(props) =>
    props.wrap === 'fit' &&
    `
      width: fit-content;
      text-align: center;
    `}
    ${(props) =>
    props.disabled &&
    `
      background: var(--bg-disabled-color);
      color: var(--white-color);
      opacity: 0.3;
    `}
    ${(props) =>
    props.weight &&
    `
    font-weight: ${props.weight};
    `}
    ${(props) =>
    props.size === "small" &&
    `
      font-size: 16px;
    `}
`;

export default {
  Button,
};
