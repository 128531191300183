import investorReducer from './investor/slice';
import mqttReducer from './mqtt/slice';
import subscriptionReducer from './subscription/slice';
import systemReducer from './system/slice';
import { combineReducers } from '@reduxjs/toolkit';

const createRootReducer = () => {
  return combineReducers({
    investor: investorReducer,
    system: systemReducer,
    subcription: subscriptionReducer,
    mqttConnection: mqttReducer,
  });
};

export default createRootReducer;
