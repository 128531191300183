// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!./fonts/fonts.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!../../node_modules/react-loading-skeleton/dist/skeleton.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (min-width: 768px) {\n  .ltr {\n    direction: ltr;\n  }\n  .rtl {\n    direction: rtl;\n  }\n}\n\n.Toastify__toast-container {\n  z-index: 9999999;\n}\n\n.custom-truncate-1 {\n  display: -webkit-box;\n  -webkit-line-clamp: 1;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n", "",{"version":3,"sources":["webpack://src/styles/global.css"],"names":[],"mappings":"AAEA;EACE;IACE,cAAc;EAChB;EACA;IACE,cAAc;EAChB;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":["@import 'fonts/fonts.css';\n@import 'react-loading-skeleton/dist/skeleton.css';\n@media (min-width: 768px) {\n  .ltr {\n    direction: ltr;\n  }\n  .rtl {\n    direction: rtl;\n  }\n}\n\n.Toastify__toast-container {\n  z-index: 9999999;\n}\n\n.custom-truncate-1 {\n  display: -webkit-box;\n  -webkit-line-clamp: 1;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
