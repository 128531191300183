import Item from './Item';
import { clearNotification } from 'api/investor/request';
import { IError } from 'api/types';
import Container from 'components/InvestorCommon/Box/Box';
import Text from 'components/InvestorCommon/Text/Text';
import Title from 'components/InvestorCommon/Text/Title';
import { useAppSelector } from 'hooks/reduxHook';
import { queryCache, useMutation } from 'react-query';
import { RefetchOptions } from 'react-query/types/core/query';
import { getUser } from 'store/ducks/investor/slice';
import styled from 'styled-components';

const NotifyContainer = styled(Container)`
  @media (max-width: 768px) {
    max-width: 300px;
  }
`;

interface INotification {
  listNotification: ItemType[];
  refetch: (options?: RefetchOptions | undefined) => Promise<any>;
  setShowNotification: () => void;
}

interface ItemType {
  noti_id: number;
  noti_body: string;
  noti_image: string;
  noti_create_utc: string;
  noti_link: string;
  noti_status: boolean;
}

const Notification = (props: INotification) => {
  const { listNotification = [], refetch, setShowNotification } = props;
  const dataUser = useAppSelector(getUser);

  const [mutate] = useMutation(clearNotification, {
    onSuccess: (data) => {
      queryCache.invalidateQueries('/user/notifications');
    },
    onError: (error: IError) => {},
  });

  const clearNotificationFunc = () => {
    const params = {
      user_id: String(dataUser.id),
    };
    mutate(params);
  };
  return (
    <NotifyContainer style={{ maxHeight: '75vh' }} className="p-5 mb-10" width="575px">
      <div className="flex justify-between pb-5 pt-1 border-b ">
        <div>
          <Title level={5}>Notification</Title>
        </div>
        <div>
          <Text onClick={clearNotificationFunc} size="17px" color="#2E3192">
            Clear All
          </Text>
        </div>
      </div>
      <div style={{ maxHeight: '60vh' }} className="pr-2 mt-5 overflow-y-scroll">
        {listNotification.map((record: ItemType) => (
          <Item
            dateTime={record.noti_create_utc}
            imgUrl={record.noti_image}
            title={record.noti_body}
            key={record.noti_id}
            notiId={record.noti_id}
            link={record.noti_link}
            status={record.noti_status}
            refetch={refetch}
            setShowNotification={setShowNotification}
          />
        ))}
      </div>
    </NotifyContainer>
  );
};

export default Notification;
