import S from './styled';
import { IInputProps } from './type';
import { ComponentProps, forwardRef, useMemo, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { IMAGES } from 'utils/images';

type Props = Omit<ComponentProps<any>, ''> & IInputProps;

const Input = forwardRef<HTMLInputElement, Props>(function Input(
  {
    children,
    onClick,
    radius,
    width,
    className,
    unit,
    text,
    theme,
    size,
    placeholder,
    title,
    mode,
    labelClass,
    labelStyle,
    type,
    disabled,
    errorMessage,
    style,
    readonly,
    suffix,
    errorClassName,
    ...props
  },
  ref
) {
  const [currentType, setCurrentType] = useState(type);

  const eyes = useMemo(() => {
    let temp = currentType === 'password' ? <AiOutlineEyeInvisible /> : <AiOutlineEye />;
    return temp;
  }, [currentType]);

  return (
    <div className="w-full">
      <div>{title && <S.Title>{title}</S.Title>}</div>
      <div
        style={{
          marginTop: `${title ? '10' : '0'}px`,
          position: 'relative',
        }}
      >
        {mode == 'search' && (
          <img
            src={IMAGES.investorHomepage.search}
            width="14px"
            height="14px"
            style={{
              position: 'absolute',
              top: '14px',
              left: '27px',
            }}
          />
        )}
        {suffix && suffix}
        {mode == 'show-password' &&
          (currentType === 'password' ? (
            <AiOutlineEye
              size={16}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '16px',
                right: '15px',
              }}
              onClick={() => setCurrentType('text')}
            />
          ) : (
            <AiOutlineEyeInvisible
              size={16}
              style={{
                position: 'absolute',
                top: '16px',
                right: '15px',
              }}
              onClick={() => setCurrentType('password')}
            />
          ))}
        <S.Input
          ref={ref}
          style={style}
          onClick={onClick}
          type={currentType}
          unit={unit}
          mode={mode}
          theme={theme}
          text={text}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readonly}
          {...props}
        >
          {children}
        </S.Input>
        {unit && <S.Unit>{unit}</S.Unit>}
        {errorMessage && (
          <div
            className={errorClassName}
            style={{
              color: '#EA3F30',
              font: 'normal normal bold 14px/18px Lato',
              marginTop: '5px',
            }}
            dangerouslySetInnerHTML={{ __html: errorMessage || 'Error' }}
          />
        )}
      </div>
    </div>
  );
});

export default Input;
