import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LOCAL_STORAGE } from 'utils/constant';

export interface ISystemStore {
  type: string;
}

const initialState: ISystemStore = {
  type: localStorage.getItem(LOCAL_STORAGE.type) || '',
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<ISystemStore['type']>) => {
      return {
        ...state,
        type: action.payload,
      };
    },
  },
});

export const getType = (state: RootState) => state.system.type;

export const { setType } = systemSlice.actions;

export default systemSlice.reducer;
