import AnnouncementContent from './AnnouncementContent';
import Drawer from 'components/InvestorCommon/Drawer/Drawer';

interface Props {
  showAnnouncement: boolean;
  setShowAnnouncement: any;
}
const AnnouncementDrawer = (props: Props) => {
  const { showAnnouncement, setShowAnnouncement } = props;
  return (
    <div>
      <Drawer isOpen={showAnnouncement} setIsOpen={setShowAnnouncement} children={<AnnouncementContent setShowAnnouncement={setShowAnnouncement}/>} />
    </div>
  );
};

export default AnnouncementDrawer;
