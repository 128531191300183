/* eslint-disable no-underscore-dangle */
import { ITokenJWT } from 'api/types';
import { BN } from 'ethereumjs-util';
import { fromWei, toBN, Hex, toWei } from 'web3-utils';

/**
 * @deprecated use convert unit instead
 * @param amount
 * @param decimal
 */
export function convertNumber(amount: number | string, decimal: number): BN {
  // @ts-expect-error Property 'modrn' is missing in type
  return toBN(amount).divn(10 ** decimal);
}

/**
 *
 * @param value
 * @param decimals
 */
export function convertUnit(value: { _hex: Hex } | number | undefined, decimals = 0): number {
  let inputValue = 0;
  if (typeof value === 'number') {
    inputValue = value;
  } else {
    inputValue = Number(toBN(value?._hex ?? 0).toString());
  }

  return Number(inputValue / 10 ** decimals);
}

/**
 *
 * @param value
 */
export function hex2int(value: { _hex: Hex }): number {
  return convertUnit(value);
}

/**
 *
 * @param val
 */
export function centsToUsd(val: number): number {
  return Number(val / 100);
}

/**
 *
 * @param cents
 */
export function hex2usd(cents: { _hex: Hex }): number {
  return !cents?._hex ? 0 : centsToUsd(Number(toBN(cents._hex).toString()));
}

/**
 *
 * @param wei
 */
export function hex2eth(wei: { _hex: Hex }): number | string {
  return !wei?._hex ? 0 : fromWei(toBN(wei._hex), 'ether');
}

/**
 *
 * @param val
 */
export function usdToCents(val: number): number {
  return Number(val * 100);
}

/**
 *
 * @param val
 */
export function ethToWei(val: number): BN {
  // @ts-expect-error Property 'modrn' is missing in type
  return toWei(toBN(val * 10 ** 18), 'wei');
}

/**
 *
 * @param balance
 * @param decimals
 */
export function formatCurrency(
  balance:
    | number
    | {
        _hex: string | number;
      }
    | undefined,
  decimals = 0
): string {
  if (typeof balance === 'number') {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: Math.min(2, decimals),
      maximumFractionDigits: Math.max(2, decimals),
    }).format(balance);
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: Math.min(2, decimals),
    maximumFractionDigits: Math.max(2, decimals),
  }).format(convertUnit(balance, decimals));
}

/**
 * @deprecated use convertUnit instead
 */
export function convertUnitValue(unit: 'cents' | 'Wei' | 'Satoshi', balance: { _hex: Hex }): number {
  const convertMapping: Record<string, Function> = {
    Wei: hex2eth,
    cents: hex2usd,
    Satoshi: (value: { _hex: Hex }) => hex2int(value) / 10 ** 10,
  };

  return convertMapping?.[unit]?.(balance);
}

/**
 *
 * @param number
 * @param decimals
 */
export function formatNumber(number: number, decimals = 2): string {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

/**
 *
 * @param s
 * @param n
 */
export function truncateMiddle(s: string, n = 16): string {
  return s?.length > n && s?.length > 0
    ? `${s.substr(0, s.length / 2 - (s.length - n) / 2)}…${s.substr(s.length / 2 + (s.length - n) / 2)}`
    : s;
}

/**
 *
 * @param number
 * @param blockTime
 */
export function dateFromBlocks(number: number, blockTime = 15): string {
  return `${Math.floor((number * blockTime) / 60 / 60 / 24)} days`;
}


export function getJWTPayload(jwt: string) {
  // A JWT has 3 parts separated by '.'
  // The middle part is a base64 encoded JSON
  // decode the base64
  return atob(jwt.split('.')[1]);
}

export function checkAccessTokenExpired(exampleJWT: string) {
  const payload = getJWTPayload(exampleJWT) as unknown as ITokenJWT;

  const expiration = new Date(payload?.exp);
  const now = new Date();
  return expiration.getTime() < now.getTime();
}

export default {
  hex2eth,
  hex2int,
  hex2usd,
  centsToUsd,
  convertUnitValue,
  convertNumber,
  usdToCents,
  ethToWei,
  formatCurrency,
  formatNumber,
  convertUnit,
  truncateMiddle,
  dateFromBlocks,
};
