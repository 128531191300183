import { axiosTradingInstance } from 'api/axiosTrading';
import {
  BankAccountInfo,
  GoogleUserInfoRequest,
  ICancelSubscriptionRequest,
  IChangePasswordRequest,
  IChangePasswordVerification,
  ICheckMailExistedRequest,
  IClearNotification,
  IFinancialInformation,
  IInvestorTypeRequest,
  ILoginRequest,
  ILoginWithSocialRequest,
  IMarketCancelOrderRequestParams,
  IMessageRequest,
  IPersonalInfoRequest,
  IResetPasswordRequest,
  IRiskProfileRequest,
  ISetPasswordCorporateRequest,
  ISettingRequest,
  ISignUpCorporateInvestorRequest,
  ISignUpInvestorRequest,
  ISignUpWithSocialRequest,
  IssuanceInterest,
  IVerifyAuthorizedPesonToken,
  IVerifyTokenRequest,
  VerifyChangeMFA,
  VerifySetupMFA,
  VerifyWithDrawal,
} from './types';
import { axiosInstance, baseAxios } from 'api/axios';

export const SignUpInvestor = async (request: ISignUpInvestorRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/sign-up`, request);
  return data;
};
export const SignUpCorporateInvestor = async (request: ISignUpCorporateInvestorRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/corporate/sign-up`, request);
  return data;
};
export const signUpInvestorWithSocial = async (request: ISignUpWithSocialRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/social/sign-up`, request);
  return data;
};

export const loginInvestorWithSocial = async (request: ILoginWithSocialRequest): Promise<any> => {
  const data = await axiosInstance.post(`/investors/social/sign-in`, request);
  return data;
};

export const getGoogleUserInfo = async (request: GoogleUserInfoRequest): Promise<any> => {
  return await axiosInstance.post(`/users/google/get-info`, request);
};

export const checkEmailExisted = async (request: ICheckMailExistedRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/validate-account`, request);
  return data;
};

export const verifyEmail = async (request: IVerifyTokenRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/send-verify-email`, request);
  return data;
};
export const verifyCorporateEmail = async (request: IVerifyTokenRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/corporate/send-verify-email`, request);
  return data;
};
export const submitPersonalInfo = async (request: IPersonalInfoRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/personal-information`, request);
  return data;
};

export const financialInformation = async (request: IFinancialInformation): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/financial-information`, request);
  return data;
};

export const login = async (request: ILoginRequest): Promise<any> => {
  const data = await axiosInstance.post(`/investors/sign-in`, request);
  return data;
};

export const submitInvestorType = async (request: IInvestorTypeRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/investor-types`, request);
  return data;
};

export const submitRiskProfile = async (request: IRiskProfileRequest | any): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/risk-profiles`, request);
  return data;
};

export const submitInvestorApplication = async (request: { isEmployeeOfKldx: boolean }): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/applications`, request);
  return data;
};

export const submitInvestorDocument = async (request: FormData): Promise<any> => {
  const response = await axiosInstance.post(`/investor/24/uploadNRIC`, request);
  return response;
};

export const submitInvestorMessage = async (request: IMessageRequest): Promise<any> => {
  const response = await axiosInstance.post(`/investors/messages`, request);
  return response;
};

export const submitInvestorSetting = async (request: ISettingRequest): Promise<any> => {
  const response = await axiosInstance.put(`/investors/profile-settings`, request);
  return response;
};

export const issuanceInterest = async (request: IssuanceInterest): Promise<any> => {
  const response = await axiosInstance.post(`/investors/issuance-interest`, request);
  return response;
};

export const submitInvestorProfileStep1 = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/investors/personal-info`, request);
  return response;
};

export const submitInvestorProfileStep2 = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/investors/employee-info`, request);
  return response;
};

export const submitInvestorProfileStep3 = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/investors/investing-info`, request);
  return response;
};

export const postVerification = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/ctos/create-transaction`, request);
  return response;
};

export const submitInvestorProfileStep5 = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/investors/declaration-info`, request);
  return response;
};

export const submitEddInformation = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/investors/update/edd`, request);
  return response;
};

export const submitInvestorProfileToAML = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/investors/AMl/online-scan`, request);
  return response;
};

export const forgotPassword = async (request: ICheckMailExistedRequest): Promise<any> => {
  const data = await axiosInstance.post(`/users/forward-password`, request);
  return data;
};

export const resetPassword = async (request: IResetPasswordRequest): Promise<any> => {
  const data = await axiosInstance.post(`/users/change-password`, request);
  return data;
};

// CIP3
export const submitActorCIP3 = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/corporate-investors/actor`, request);
  return response;
};

export const deleteActorCIP3 = async (actorId: string): Promise<any> => {
  const response = await axiosInstance.delete(`/corporate-investors/actor`, { data: { actorId } });
  return response;
};

export const updateActorCIP3 = async (actorId: string, updateActorInfo: any): Promise<any> => {
  const response = await axiosInstance.put(`/corporate-investors/actor`, { actorId, updateActorInfo });
  return response;
};

export const uploadFileCIP3 = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/document-tracker`, request);
  return response;
};
// CIP3

export const setPasswordCorporate = async (request: ISetPasswordCorporateRequest): Promise<any> => {
  const data = await axiosInstance.post(`/corporate-investor/change-password-first-time`, request);
  return data;
};

export const setPasswordOmnibus = async (request: ISetPasswordCorporateRequest): Promise<any> => {
  const data = await axiosInstance.post(`/corporate-omnibus/change-password-first-time`, request);
  return data;
};
export const changePasswordVerification = async (request: IChangePasswordVerification): Promise<any> => {
  const data = await axiosInstance.put(`/users/2fa/Regenerate`, request);
  return data;
};
export const verifyAuthorizedPesonToken = async (request: IVerifyAuthorizedPesonToken): Promise<any> => {
  const data = await axiosInstance.post(`/corporate-investor/verify-authorized-person-token`, request);
  return data;
};
export const clearNotification = async (request: IClearNotification): Promise<any> => {
  const data = await axiosInstance.post(`/user/notifications/clear-all`, request);
  return data;
};

export const changePin = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/investors/change-pin`, request);
  return data;
};

export const createNewPin = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/investors/update/transaction-pin`, request);
  return data;
};

export const changePassword = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/investors/change-password`, request);
  return data;
};

export const changePasswordSocial = async (request: IChangePasswordRequest): Promise<any> => {
  const data = await axiosInstance.post(`/investors/social/change-password`, request);
  return data;
};

export const checkChangePassword = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/investors/login/social/check-pass`, request);
  return data;
};

export const checkChangePin = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/investors/login/social/check-pin`, request);
  return data;
};

export const verifyWithdrawal = async (request: VerifyWithDrawal): Promise<any> => {
  const { data } = await axiosInstance.post(`/investor/withdrawal`, request);
  return data;
};
export const verifyResetPasswordToken = async (request: IVerifyAuthorizedPesonToken): Promise<any> => {
  const { data } = await axiosInstance.post(`/corporate-investor/verify-authorized-person-token`, request);
  return data;
};
export const verifySetupMFA = async (request: VerifySetupMFA): Promise<any> => {
  const data = await axiosInstance.post(`/users/2fa/verify`, request);
  return data;
};

export const verifyChangeMFA = async (request: VerifyChangeMFA): Promise<any> => {
  const data = await axiosInstance.put(`/users/2fa/verify`, request);
  return data;
};

export const submitBankAccountInfo = async (request: BankAccountInfo): Promise<any> => {
  const { data } = await axiosInstance.post(`/investors/bank`, request);
  return data;
};

export const downloadPDF = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/investors/wallet/topUp/download-PDF`, request, {
    responseType: 'arraybuffer',
  });
  return data;
};

export const cancelSubscription = async (request: ICancelSubscriptionRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/projects/subscription/cancel`, request);
  return data;
};
export const cancelSubscriptionChildAccount = async (request: ICancelSubscriptionRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/omnibus-account/cancel-subscription`, request);
  return data;
};
export const downloadCashAccountPDF = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/investors/wallet/transactions/download-PDF-cash`, request, {
    responseType: 'arraybuffer',
  });
  return data;
};

export const downloadTokenAccountPDF = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/investors/wallet/transactions/download-PDF-token`, request, {
    responseType: 'arraybuffer',
  });
  return data;
};

export const downLoadAllStatementsCash = async (params: any): Promise<any> => {
  const { data, headers } = await baseAxios.get(`/investors/wallet/transactions/download`, {
    params,
    responseType: 'arraybuffer',
  });
  return { data, contentType: headers['content-type'] };
};

export const downLoadAllStatementsToken = async (params: any): Promise<any> => {
  const { data, headers } = await baseAxios.get(`/investors/token/transactions/download`, {
    params,
    responseType: 'arraybuffer',
  });
  return { data, contentType: headers['content-type'] };
};

export const downloadAllStatements = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/investors/transactions/download-all-statement`, request, {
    responseType: 'arraybuffer',
  });
  return data;
};
export const downloadAllStatementsMonthly = async (params: any): Promise<any> => {
  const data = await axiosInstance.get(`/statement/download-total-PDF`, {
    params,
    responseType: 'arraybuffer',
  });
  return data;
};
export const downloadAllTotalStatementsMonthly = async (params: any): Promise<any> => {
  const data = await axiosInstance.get(`/statement/download-total-all-PDF`, {
    params,
    responseType: 'arraybuffer',
  });
  return data;
};
export const submitInvestorProfileCorporate = async (request: any): Promise<any> => {
  const response = await axiosInstance.put(`/corporate-investors/profile`, request);
  return response;
};
export const get2FAGenerate = async (): Promise<any> => {
  const response = await axiosInstance.get(`/users/2fa/generate`);
  return response;
};
export const activeStatusInvestor = async (): Promise<any> => {
  const response = await axiosInstance.post(`/investors/reactivation-request`);
  return response;
};

export const verifyAuthorizedPesonOmnibusToken = async (request: IVerifyAuthorizedPesonToken): Promise<any> => {
  const data = await axiosInstance.post(`/corporate-omnibus/verify-authorized-person-token`, request);
  return data;
};
export const createCorporateActor = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/corporate-investors/actor`, request);
  return response;
};

export const updateCorporateActor = async (actorId: string, updateActorInfo: any, isDraft?: boolean): Promise<any> => {
  const response = await axiosInstance.put(`/corporate-investors/actor`, { actorId, updateActorInfo, isDraft });
  return response;
};

export const saveAllActor = async (): Promise<any> => {
  const response = await axiosInstance.post(`/corporate-investors/actor/save`);
  return response;
};

export const marketCancelOrderRequest = async (
  params: IMarketCancelOrderRequestParams
): Promise<any> => {
  const { orderId } = params;
  const response = await axiosTradingInstance.post(`/market/order/cancel-order`, { orderId });
  return response;
};
