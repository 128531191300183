import {IChangePasswordRequest, IChangePasswordResponse, INonInvestorInterest} from './types';
import {axiosInstance} from 'api/axios';
import axios from 'axios';
import Cookies from 'js-cookie';
import cookieOptions from "../../utils/cookieOptions";

export const SignIn = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/users/login`, request);
  return data;
};

export const SignUp = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/lead`, request);
  return data;
};

export const changePassword = async (request: IChangePasswordRequest): Promise<IChangePasswordResponse> => {
  const { data } = await axiosInstance.post(`/user/password`, request);
  return data;
};

export const addNonInvestorInterest = async (request: INonInvestorInterest): Promise<any> => {
  const { data } = await axiosInstance.post(`/user/investor/addInterest/${request.issuance_id}`, request);
  return data;
};

export const addInvestorInterest = async (request: INonInvestorInterest): Promise<any> => {
  const { data } = await axiosInstance.post(
    `/user/${request.user_id}/investor/addInterest/${request.issuance_id}`,
    request
  );
  return data;
};

export const logout = async (): Promise<any> => {
  return await axios
      .post(`${process.env.WEB_API_URL}/users/token/clear`,
          {
            refreshToken: Cookies.get('refreshToken')
          }
  );
};

export const refreshToken = async (): Promise<any> => {
  const res = await axios
    .post(
      `${process.env.WEB_API_URL}/users/token/refresh`,
      {
        refreshToken: Cookies.get('refreshToken')
      }
    )
    .catch((error) => {
      localStorage.clear();
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      sessionStorage.clear();
      // history.push(routesEnum.home);
      window.location.href = '/';
      return error;
    });
  const { data } = res;
  Cookies.set('token', data?.user?.token, cookieOptions);
  Cookies.set('refreshToken', data?.user?.refreshToken, cookieOptions);
  return data;
};
