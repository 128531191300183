import { ITitleProps, ITextProps } from './type';
import styled from 'styled-components';

const Title = styled.h5<ITitleProps>`
  margin-bottom: 0;
  color: var(--black-color);
  font-size: 14px;
  word-wrap: break-word;
  font-weight: 700;

  @media (min-width: 384px) {
    ${(props) =>
      props.level === 1 &&
      `
        font-size: 24px;
        line-height: 29px;
      `}
    ${(props) =>
      props.level === 2 &&
      `
        font-size: 20px;
        line-height: 22px;
      `}
      ${(props) =>
      props.level === 3 &&
      `
        font-size: 14px;
        line-height: 21px;
      `}
      ${(props) =>
      props.level === 4 &&
      `
        font-size: 12px;
        line-height: 15px;
      `}
     ${(props) =>
      props.level === 5 &&
      `
        font-size: 10px;
        line-height: 12px;
      `}
      ${(props) =>
      props.level === 7 &&
      `
        font-size: 30px;
        line-height: 40px;
      `}
  }
  @media (min-width: 768px) {
    ${(props) =>
      props.level === 1 &&
      `
      font-size: 30px;
      line-height: 34px;
    `}
    ${(props) =>
      props.level === 2 &&
      `
      font-size: 26px;
      line-height: 28px;
    `}
    ${(props) =>
      props.level === 3 &&
      `
      font-size: 22px;
      line-height: 24px;
    `}
    ${(props) =>
      props.level === 4 &&
      `
      font-size: 18px;
      line-height: 22px;
    `}
   ${(props) =>
      props.level === 5 &&
      `
      font-size: 14px;
      line-height: 14px;
    `}
    ${(props) =>
      props.level === 7 &&
      `
        font-size: 30px;
        line-height: 40px;
      `}
  }
  @media (min-width: 1920px) {
    font-weight: 800;
    ${(props) =>
      props.level === 1 &&
      `
        font-size: 48px;
        line-height: 58px;
      `}
    ${(props) =>
      props.level === 2 &&
      `
        font-size: 40px;
        line-height: 44px;
      `}
      ${(props) =>
      props.level === 3 &&
      `
        font-size: 34px;
        line-height: 42px;
      `}
      ${(props) =>
      props.level === 4 &&
      `
        font-size: 25px;
        line-height: 30px;
      `}
     ${(props) =>
      props.level === 5 &&
      `
        font-size: 20px;
        line-height: 24px;
      `}
       ${(props) =>
      props.level === 6 &&
      `
        font-size: 22px;
        line-height: 27px;
      `}
      ${(props) =>
      props.level === 7 &&
      `
        font-size: 30px;
        line-height: 40px;
      `}
  }

  ${(props) =>
    `
      color: ${props.color};
    `}
  ${(props) =>
    `
      text-transform: ${props.text};
    `}
    ${(props) =>
    `
      font-weight: ${props.weight} !important;
    `}
`;

const Paragraph = styled.div<ITextProps>`
  color: var(--black-color);
  font-size: 14px;
  word-wrap: break-word;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
  }
  ${(props) =>
    `
      font-size: ${props.size};
      line-height: ${props.size};
    `}
  ${(props) =>
    `
      text-transform: ${props.text};
    `}
  ${(props) =>
    `
      font-weight: ${props.weight};
    `}

    ${(props) =>
    `
        color: ${props.color};
      `}
`;

const Text = styled.div<ITextProps>`
  color: var(--black-color);
  font-size: 14px;
  word-wrap: break-word;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
  }
  ${(props) =>
    `
      color: ${props.color};
    `}
  ${(props) =>
    `
      color: var(--${props.theme}-color);
  `}
  ${(props) =>
    `
      font-size: ${props.size}px !important;
      line-height: ${Number(props.size) + 6}px !important;
    `}
  ${(props) =>
    `
      text-transform: ${props.text};
    `}
  ${(props) =>
    `
      font-weight: ${props.weight}
    `}
`;

export default {
  Title,
  Paragraph,
  Text,
};
