import Button from '../Button/Button';
import { Text } from '../TextV2';
import Item from './Item';
import { Container } from './Styled';
import Tab from './Tab';
import { useGetIssuerAnnouncement } from 'api/investor/queries';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IMAGES } from 'utils/images';

interface Props {
  setShowAnnouncement: (isOpen: boolean) => void;
}

interface ItemType {
  id: string;
  companyName: string;
  category: string;
  subCategory: string;
  dateAnnouncement: string;
  other: string;
}

const listTab = [
  {
    key: '1',
    title: 'General News',
  },
  {
    key: '2',
    title: 'My News',
  },
  {
    key: '3',
    title: 'Issuer Announcement',
  },
];

const AnnouncementContent = (props: Props) => {
  const { setShowAnnouncement } = props;
  const [currentTab, setCurrentTab] = useState('Issuer Announcement');
  const { data: IssuerAnnouncement } = useGetIssuerAnnouncement({ page: 1, pageSize: 5 });
  const [Data, setData] = useState<any>({
    'General News': [],
    'My News': [],
    'Issuer Announcement': [],
  });

  useEffect(() => {
    setData({
      'General News': [],
      'My News': [],
      'Issuer Announcement': IssuerAnnouncement?.listAnnouncement,
    });
  }, [IssuerAnnouncement]);

  const history = useHistory();
  return (
    <Container>
      <div className="flex flex-row">
        {listTab.map((item) => {
          return (
            <Tab
              onClick={() => setCurrentTab(item.title)}
              isChoice={currentTab === item.title}
              key={item.key}
              title={item.title}
            />
          );
        })}
      </div>
      {Data[currentTab]?.length && (
        <div>
          {Data[currentTab]?.map((item: ItemType) => {
            return (
              <Item
                key={item?.id}
                companyName={item?.companyName}
                category={item?.category}
                subCategory={item?.subCategory}
                dateAnnouncement={item?.dateAnnouncement}
                other={item?.other}
                onClick={() => {
                  history.push(`/investors/announcement-detail/${item?.id}`);
                  setShowAnnouncement(false);
                }}
              />
            );
          })}
          <div className="flex justify-center item-center mt-4 mb-40">
            <Button
              className="gap-2"
              onClick={() => {
                history.push('/investors/announcement-list');
                setShowAnnouncement(false);
              }}
            >
              <Text level={4} color="#2E3192">
                See more
              </Text>
              <img alt='' src={IMAGES.icon.see_more} style={{ width: '22px', height: '22px' }} />
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default AnnouncementContent;
