import S from './styled';
import { IBoxProps } from './type';
import React from 'react';

const Container: React.FC<IBoxProps> = ({ style, children, bgColor, className, ...props }) => {
  return (
    <S.Box style={style} bgColor={bgColor} className={`sm:px-4 ${className}`} {...props}>
      {children}
    </S.Box>
  );
};

export default Container;
