import { useState } from 'react';
import { createContainer } from 'unstated-next';

export interface UISettingResponse {
  status: string;
  data: Data;
}

export interface Data {
  accounts: Account[];
  settings: Setting[];
}

export interface Account {
  account: string;
  name: string;
  imgUrl: string;
  balance: number;
  updatedAt: string;
  checkBalance: number;
  purpose: null | string;
}

export interface Setting {
  id: number;
  config_key: string;
  config_value: string;
}

export function useUI(): Record<any, any> {
  const [blockchainView, setBlockchainView] = useState<boolean>(false);
  const [screenSize, setScreenSize] = useState('small');
  const [showUserMenu, setShowUserMenu] = useState(false);

  const settingsUrl = '/api/settings';

  const fetchSettings = async () => {
    return fetch(settingsUrl, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        return result.data;
      });
  };

  // const { data } = useQuery<Data>(settingsUrl, fetchSettings);

  const getSetting = (settingKey: string) => {
    // return data?.settings?.find((setting) => setting?.config_key === settingKey)?.config_value;
    return [];
  };

  return {
    blockchainView,
    setBlockchainView,
    screenSize,
    setScreenSize,
    showUserMenu,
    setShowUserMenu,
    // settings: data?.settings ?? [],
    settings: [],
    getSetting,
    getAccountNames: () => {
      return {};
      // return data?.accounts.reduce((accounts, account) => {
      //   return {
      //     ...accounts,
      //     [account.account.toLowerCase()]: `${process.env.WEB_COMPANY_NAME} Pte. Ltd.`,
      //     [account.account.toUpperCase()]: `${process.env.WEB_COMPANY_NAME} Pte. Ltd.`,
      //   };
      // }, {});
    },
    uiStatus: {
      // isReady: () => !!data?.settings,
      isReady: () => false,
      isExchangeClosed: () => Number(getSetting('web_settings_closeExchange')) === 1,
      openDemoRegistration: () => Number(getSetting('web_settings_openDemoRegistration')) === 1,
      disableExplorer: () => Number(getSetting('web_settings_disableExplorer')) === 1,
      isMFADisabled: () => Number(getSetting('web_settings_disableMFA')) === 1,
      isDashboardDisabled: () => Number(getSetting('web_settings_disableDashboard')) === 1,
    },
    getAnnouncement: () => getSetting('web_settings_announcement'),
  };
}

export const UI = createContainer(useUI);
