import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface ISubscriptionStore {
  amount: number;
  fee: number;
  totalAmountBePaid: number;
  errorMessage: string;
}

const initialState: ISubscriptionStore = {
  amount: 0,
  fee: 0,
  totalAmountBePaid: 0,
  errorMessage: '',
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setAmount: (state, action: PayloadAction<ISubscriptionStore['amount']>) => {
      return {
        ...state,
        amount: action.payload,
      };
    },
    setFee: (state, action: PayloadAction<ISubscriptionStore['fee']>) => {
      return {
        ...state,
        fee: action.payload,
      };
    },
    setTotalAmountBeFaid: (state, action: PayloadAction<ISubscriptionStore['totalAmountBePaid']>) => {
      return {
        ...state,
        totalAmountBePaid: action.payload,
      };
    },
    setErrorMessage: (state, action: PayloadAction<ISubscriptionStore['errorMessage']>) => {
      return {
        ...state,
        errorMessage: action.payload,
      };
    },
  },
});

export const getAmount = (state: RootState) => state.subcription.amount;
export const getFee = (state: RootState) => state.subcription.fee;
export const getTotalAmountBeFaid = (state: RootState) => state.subcription.totalAmountBePaid;
export const getErrorMessage = (state: RootState) => state.subcription.errorMessage;

export const { setAmount, setFee, setTotalAmountBeFaid, setErrorMessage } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
