import { ContainerTabType } from './Types';
import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  max-width: 500px;
  height: 100%;
  background-color: #ffffff;
`;

export const ContainerTab = styled.div<ContainerTabType>`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  width: 33.333%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isChoice ? '#FFFFFF' : '#F2F3F7')};
  @media (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }
  @media (max-width: 640px) {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  :hover{
    cursor: pointer;
  }
`;

export const ContainerItem = styled.div`
  padding-left: 30px;
  padding-right: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-color: #d9d9d9;
  border-bottom-width: 1px;
  display: flex;
  flex-direction: row;
  @media (max-width: 640px) {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ImageItem = styled.img`
  width: 66px;
  height: 66px;
  border-radius: 10px;
  margin-right: 23px;
`;

export const InfoItem = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 4px;
`;

export const TitleItem = styled.div`
  color: #2d3436;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const DateTimeItem = styled.div`
  color: #5b5d6b;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
