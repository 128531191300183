import { IBoxProps } from './type';
import styled from 'styled-components';
import { propTypes } from 'cleave.js/react';

const Box = styled.div<IBoxProps>`
  background: var(--white-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--secondary-border-color);
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 15px;
  ${(props) =>
    `
    background-color: ${props.bgColor};
    `}
  ${(props) =>
    props.theme == 'secondary' &&
    `
      background: var(--bg-secondary-color);
      border: 0;
    `}
    ${(props) =>
      props.theme == 'fourth' &&
      `
        border-radius: 5px;
      `}
  ${(props) =>
    `
    width: ${props.width};
  `}
`;

export default {
  Box,
};
