/* eslint-disable jsx-a11y/alt-text */
import {
  collapseIcon,
  CollapseIconButton,
  expandIcon,
  Image,
  WrapperMobileSidebar,
  WrapperProSidebar,
  WrapperrSideBar,
} from './styled';
import { useGetNotification } from 'api/investor/queries';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHook';
import Cookies from 'js-cookie';
import { routesEnum } from 'pages/Routes';
import { FC } from 'react';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { useHistory, useLocation } from 'react-router-dom';
import { getUser } from 'store/ducks/investor/slice';
import { setType } from 'store/ducks/system/slice';
import { LOCAL_STORAGE, PERMISSIONS } from 'utils/constant';
import { IMAGES } from 'utils/images';

interface Props {
  menuCollapse: any;
  setMenuCollapse: any;
}

interface ISidebar {
  subMenu?: boolean;
  title: string;
  link: string;
  icon?: any;
  subItem?: ISidebar[];
}

const TOP_SIDEBAR: ISidebar[] = [
  { title: 'Home', link: '/investors', icon: <Image width="20px" height="20px" src={IMAGES.sidebar.home} /> },
  {
    subMenu: true,
    title: 'Wallet',
    link: '/investors/wallet',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.wallet} />,
    subItem: [
      {
        title: 'Wallet Details',
        link: '/investors/wallet',
      },
      {
        title: 'Token Details',
        link: '/investors/token-detail',
      },
      {
        title: 'Transactions',
        link: '/investors/transactions',
      },
    ],
  },
  {
    subMenu: true,
    title: 'Platforms',
    link: '/investors/primary-listing',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.platforms} />,
    subItem: [
      {
        title: 'Primary Listing',
        link: '/investors/primary-listing',
      },
      {
        title: 'Trading Platform',
        link: '/investors/trading-platform',
      },
    ],
  },
  {
    title: 'Announcement',
    link: '/investors/announcement-list',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.announcement} />,
  },
];

const FOOTER_SIDEBAR_WITH_CORP: ISidebar[] = [
  {
    subMenu: true,
    title: 'Settings',
    link: '/investors/setting',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.settings} />,
    subItem: [
      {
        title: 'Account',
        link: '/investors/coporate/account',
      },
      {
        title: 'Company Info',
        link: '/investors/coporate/companyInfo',
      },
      {
        title: 'User Management',
        link: '/investors/coporate/userManagement',
      },
      {
        title: 'Referral',
        link: '/investors/referral',
      },
    ],
  },
];

const FOOTER_SIDEBAR_WITHOUT_CORP: ISidebar[] = [
  {
    subMenu: true,
    title: 'Settings',
    link: '/investors/setting',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.settings} />,
    subItem: [
      {
        title: 'Account',
        link: '/investors/individual/account',
      },
      {
        title: 'Investor Info',
        link: '/investors/individual/investorInfo',
      },
      {
        title: 'Referral',
        link: '/investors/referral',
      },
    ],
  },
];

const FOOTER_SIDEBAR_WITH_ISSUER: ISidebar[] = [
  {
    subMenu: true,
    title: 'Settings',
    link: '/issuer/setting',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.settings} />,
    subItem: [
      // {
      //   title: 'Account',
      //   link: '/issuer/account',
      // },
      {
        title: 'Issuer Info',
        link: '/issuer/issuerInfo',
      },
      {
        title: 'Documents',
        link: '/issuer/documents',
      },
      // {
      //   title: 'User Management',
      //   link: '/issuer/authorizedIssuerInfo',
      // },
      {
        title: 'Referral',
        link: '/investors/referral',
      },
    ],
  },
];

const SideBarHeadOptions = () => {
  const dataUser = useAppSelector(getUser);

  const { data: response = {} } = useGetNotification(dataUser?.id || '', { enabled: !!dataUser?.id });
  const listNotification = response.data || [];
  return (
    <div className="z-50 flex flex-row items-center gap-6 pl-4">
      <div className="relative cursor-pointer">
        <img src={IMAGES.header.news} />
        <span className="absolute top-0 right-0 inline-block w-2 h-2 transform translate-x-2 -translate-y-2 bg-orange-600 rounded-full"></span>
      </div>
      <div className="relative cursor-pointer">
        <img src={IMAGES.header.notification} />
        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-orange-100 transform translate-x-5 -translate-y-4 bg-orange-600 rounded-full">
          {listNotification.length}
        </span>
      </div>
    </div>
  );
};

const SidebarMoblie: FC<Props> = ({ menuCollapse, setMenuCollapse }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const permissions = localStorage.getItem(LOCAL_STORAGE.user)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.user) || '')?.permissions
    : [];

  const FOOTER_SIDEBAR = permissions.includes(PERMISSIONS.WEB_INVESTOR_CORP_MANAGE)
    ? FOOTER_SIDEBAR_WITH_CORP
    : permissions.includes(PERMISSIONS.WEB_ISSUER_AUTHORIZE)
    ? FOOTER_SIDEBAR_WITH_ISSUER
    : FOOTER_SIDEBAR_WITHOUT_CORP;

  return (
    <WrapperrSideBar className="mobile" style={{ width: !menuCollapse ? '290px' : '0px', maxWidth: '100%' }}>
      <CollapseIconButton className={`${menuCollapse ? 'expand' : 'collapse'}`}>
        <img src={menuCollapse ? expandIcon : collapseIcon} alt="" onClick={() => setMenuCollapse()} />
      </CollapseIconButton>
      <WrapperProSidebar>
        <ProSidebar collapsed={menuCollapse}>
          <SidebarContent>
            <WrapperMobileSidebar>
              <SideBarHeadOptions />
            </WrapperMobileSidebar>
            <Menu iconShape="square">
              {TOP_SIDEBAR.map((item, index) =>
                !item.subMenu ? (
                  <MenuItem
                    active={item.link === location.pathname || (index === 0 && location.pathname === '/')}
                    key={item.link}
                    icon={item.icon}
                    onClick={() => history.push(item?.link || '')}
                    style={{
                      font: 'normal normal normal 14px Lato',
                    }}
                  >
                    {item.title}
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={item.link}
                    style={{
                      font: 'normal normal normal 14px Lato',
                    }}
                  >
                    <SubMenu
                      suffix={<span className="badge yellow"></span>}
                      title={item.title}
                      icon={item.icon}
                      style={{
                        font: 'normal normal normal 14px Lato',
                      }}
                    >
                      {item.subItem?.map((item: ISidebar) => (
                        <MenuItem
                          active={item.link === location.pathname}
                          onClick={() => history.push(item?.link || '')}
                          style={{
                            font: 'normal normal normal 14px Lato',
                          }}
                        >
                          {item.title}
                        </MenuItem>
                      ))}
                    </SubMenu>
                  </MenuItem>
                )
              )}
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem
                icon={<Image width="20px" height="20px" src={IMAGES.sidebar.helpCenter} />}
                style={{
                  font: 'normal normal normal 14px Lato',
                }}
              >
                <a
                  href={`${process.env.WEB_URL_HELP_CENTER}`}
                  target="_blank"
                  style={{ color: '#FFFFFF' }}
                  rel="noreferrer"
                >
                  Help Center
                </a>
              </MenuItem>

              {FOOTER_SIDEBAR.map((item, index) =>
                !item.subMenu ? (
                  <MenuItem
                    onClick={() => history.push(item?.link || '')}
                    active={item.link === location.pathname}
                    key={item.link}
                    icon={item.icon}
                    style={{
                      font: 'normal normal normal 16px Lato',
                    }}
                  >
                    {item.title}
                  </MenuItem>
                ) : (
                  <MenuItem>
                    <SubMenu
                      suffix={<span className="badge yellow"></span>}
                      title={item.title}
                      icon={item.icon}
                      style={{
                        font: 'normal normal normal 16px Lato',
                      }}
                    >
                      {item.subItem?.map((item: ISidebar) => (
                        <MenuItem
                          key={item.link}
                          active={item.link === location.pathname}
                          onClick={() => history.push(item?.link || '')}
                          style={{
                            font: 'normal normal normal 16px Lato',
                          }}
                        >
                          {item.title}
                        </MenuItem>
                      ))}
                    </SubMenu>
                  </MenuItem>
                )
              )}
              <MenuItem
                onClick={() => {
                  dispatch(setType(''));
                  Cookies.remove('token');
                  localStorage.clear();
                  history.push(routesEnum.home);
                }}
                icon={<Image width="20px" height="20px" src={IMAGES.sidebar.logOut} />}
                style={{
                  font: 'normal normal normal 14px Lato',
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </WrapperProSidebar>
    </WrapperrSideBar>
  );
};

export default SidebarMoblie;
