import { Text } from '../TextV2';
import { ContainerItem, InfoItem } from './Styled';
import moment from 'moment';
import React from 'react';

interface Props {
  companyName: string;
  category: string;
  subCategory: string;
  dateAnnouncement: string;
  other: string;
  onClick: () => void;
}
function Item(props: Props) {
  const { companyName, category, subCategory, dateAnnouncement, other, onClick } = props;

  return (
    <>
      <ContainerItem>
        <InfoItem onClick={onClick} className="cursor-pointer">
          <Text level={3}>
            [{companyName}] {category}: {subCategory === "Others" ? other : subCategory }
          </Text>
          {dateAnnouncement && (
            <Text level={3} color="#5B5D6B">
              {moment(dateAnnouncement).format('DD-MM-YYYY')}
            </Text>
          )}
        </InfoItem>
      </ContainerItem>
    </>
  );
}

export default Item;
