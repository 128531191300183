import { IInputProps, ITitleProps } from './type';
import styled from 'styled-components';

const Input = styled.input<IInputProps>`
  width: 100%;
  border: 1px solid #d9d9d9;
  color: #2d3436;
  border-radius: 5px;
  opacity: 1;
  outline: none;
  font-size: 14px;
  padding: 12px 16px;
  &::-webkit-calendar-picker-indicator:hover {
    cursor: pointer;
  }
  &[type='date'], &[type='time'] {
    -webkit-appearance: none;
  }
  &::-webkit-date-and-time-value {
    text-align: left;
  }
  ${(props) =>
    props.mode == 'search' &&
    `
      font-size: 14px;
      padding: 9px 50px;
      background: #F2F3F7
    `}

  @media (min-width: 1536px) {
    font-size: 20px;
  }
  ${(props) =>
    props.unit &&
    `
      padding:12px 48px;
    `}

  ${(props) =>
    props.width &&
    `
      width: ${props.width}
    `}
  &::placeholder {
    color: #808080;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #808080;
  }

  &::-ms-input-placeholder {
    color: #808080;
  }

  &:disabled {
    cursor: not-allowed;
  }
  @media only screen and (max-device-width: 600px) {
    font-size: 16px;
  }
`;

const Title = styled.h2<ITitleProps>`
  color: #2d3436;
  font: normal normal bold 18px/22px Lato;
`;
const Unit = styled.span<ITitleProps>`
  position: absolute;
  color: #2d3436;
  font: normal normal bold 20px/24px Lato;
  left: 10px;
  top: 11px;
  @media (min-width: 1536px) {
    top: 14px;
  }
`;

export default {
  Input,
  Title,
  Unit,
};
