import { RenderLayout } from './RenderLayout';
import * as React from 'react';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { UI } from 'state/ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: #000;
  -webkit-font-smoothing: initial;
  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &.link {
      color: ${(props) => props.theme.brandColor};
    }
  }

  p {
    margin-bottom: 1rem;
  }

  .ac-input {
    border: 1px solid #ddd;
    background: white;
    padding: 16px;
    height: 50px;
    display: inline-flex;
    width: 100%;
    color: #222;
    border-radius: 5px;

    &:-internal-autofill-selected {
      background-color: #ededed !important;
    }

    &:focus {
      outline: none;
      border-color: ${(props) => `rgba(${props.theme.brandRbgColor},0.9);`};
    }

    &::placeholder {
      font-weight: 300;
      color: #828282;
    }
  }

  select.ac-input {
    appearance: none;
    padding-right: 16px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: 8px;
      color: black;
      z-index: 9;
    }
  }

  .contentWrapper {
    background: #f8f8f8;
    overflow: hidden;
    position: relative;
    flex-grow: 1;
    margin-top: 70px;
    position: relative;

    & > .container {
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 600px;
    }
  }

  .pageContent {
    display: flex;

    .sidebar {
      padding-left: 2rem;
      min-width: 300px;
      max-width: 300px;

      .section {
        margin-bottom: 4rem;
      }

      .title {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 2rem;
      }
    }
  }

  .markdown,
  .richtext {
    h1,
    h2,
    h3,
    h4 {
      margin: 2rem 0;
      font-weight: 700;
    }
    h3,
    h4 {
      margin: 2rem 0;
    }
    p {
      margin-bottom: 2rem;
    }
    h2 {
      font-size: 1.4rem;
      color: ${(props) => `rgba(${props.theme.brandRbgColor},1);`};
    }
    h3 {
      font-size: 1.2rem;
      font-weight: 700;
    }
    h4 {
      font-size: 1.1rem;
      font-weight: 700;
    }
    ul {
      list-style: disc;
      margin-left: 2rem;
    }
    img {
      display: inline-block;
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }
`;
type Props = {
  title?: string;
  showHeader?: boolean;
  className?: string;
};

const Layout: React.FC<Props> = ({ children, title = `${process.env.WEB_COMPANY_NAME}`, className = '' }) => {
  const { setScreenSize } = UI.useContainer();
  const wrapperEl = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const screenSize = wrapperEl?.current?.clientWidth ?? 460;
    if (screenSize <= 460) {
      setScreenSize('small');
    } else if (screenSize <= 1024) {
      setScreenSize('medium');
    } else if (screenSize <= 1280) {
      setScreenSize('large');
    } else {
      setScreenSize('xlarge');
    }
  }, [setScreenSize]);

  return (
    <Wrapper className={className} ref={wrapperEl}>
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <>
        {/* {!noHeader && <Header />} */}
        <RenderLayout>{children}</RenderLayout>
        {/* {(!noFooter || !noHeader) && <Footer />} */}
      </>
    </Wrapper>
  );
};

export default Layout;
