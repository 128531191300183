// eslint-disable-next-line import/no-unresolved
import './styles/compiled/index.css';
import '@aws-amplify/ui/dist/style.css';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import Amplify from 'aws-amplify';
import Layout from 'components/Layout';
import ScrollToTop from 'components/ScrollToTop';
import Loading from 'components/styled/Loading';
import { Grommet } from 'grommet';
import { Routes } from 'pages/Routes';
import ContractProvider from 'providers/ContractProvider';
import * as React from 'react';
import { Suspense } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoProvider } from 'socket.io-react-hook';
import { UI } from 'state/ui';
import { User } from 'state/user';
import { ThemeProvider } from 'styled-components';
import 'styles/global.css';
import 'styles/variable.css';
import { getTheme } from 'utils/common';
import { GoogleOAuthProvider } from '@react-oauth/google';

Amplify.configure({
  aws_project_region: 'ap-southeast-1',
  aws_cognito_region: 'ap-southeast-1',
  aws_user_pools_id: process.env.WEB_AWS_COGNITO_USERS_POOL_ID,
  aws_user_pools_web_client_id: process.env.WEB_AWS_COGNITO_USERS_WEB_CLIENT_ID,
  oauth: {},
});

// const Logout = React.lazy(() => import('pages/Logout'));
// const PageNotFound = React.lazy(() => import('pages/PageNotFound'));
// const SignUp = React.lazy(() => import('pages/SignUp'));
// const Explorer = React.lazy(() => import('pages/explorer'));

const myTheme = getTheme('BRAND1' as any);
// const showTokenPage = process.env.WEB_COMPANY_NAME === 'BRAND2';

const theme = {
  global: {
    colors: {
      brand: myTheme.brandColor,
      focus: myTheme.brandColor,
    },
    font: {
      size: '14px',
      height: '20px',
    },
    spacing: '2em',
  },
  checkBox: {
    color: myTheme.brandColor,
    toggle: {
      radius: '12px',
    },
    border: {
      color: '#222',
    },
    hover: {
      border: {
        color: myTheme.brandColor,
      },
    },
  },
  select: {
    icons: {
      color: '#e2e8f0',
      margin: '10px',
    },
  },
  tab: {
    active: {
      background: 'transparent',
      color: '#424242',
    },
    background: 'transparent',
    border: {
      color: 'transparent',
      active: {
        color: {
          light: myTheme.brandColor,
        },
      },
      hover: {
        color: {
          light: myTheme.brandColor,
        },
      },
    },
    color: '#828282',
    hover: {
      background: 'transparent',
      color: '#424242',
    },
    margin: {
      horizontal: 'small',
      vertical: 'none',
    },
    pad: 'small',
  },
  tip: {
    content: {
      background: '#fff',
      color: 'black',
    },
  },
};

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}


/** Global error handlers */
window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled promise rejection:', event.reason)
})

window.addEventListener('error', (event) => {
  if (event.message.includes('WebSocket')) {
    /**  Prevent the error from being displayed on the screen */
    event.preventDefault()
    console.error('WebSocket error suppressed:', event.error)
  }
})

const App: React.FC = () => {
  // Apply dark theme here
  if (window?.location?.pathname.includes('asset-finder')) {
    theme.checkBox.border.color = '#929292';
  }
  return (
    <React.Fragment>
      <Router>
        <GoogleOAuthProvider clientId={process.env.WEB_GOOGLE_SOCIAL_APP_ID || ''}>
        <IoProvider>
          <Grommet theme={theme} full>
            <ThemeProvider theme={myTheme}>
              <UI.Provider>
                <User.Provider>
                  <ContractProvider>
                    <Web3ReactProvider getLibrary={getLibrary}>
                      <Layout>
                        <ScrollToTop />
                        <Suspense fallback={<Loading />}>
                          <Routes />
                        </Suspense>
                      </Layout>
                    </Web3ReactProvider>
                  </ContractProvider>
                </User.Provider>
              </UI.Provider>
            </ThemeProvider>
          </Grommet>
        </IoProvider>
        </GoogleOAuthProvider>
      </Router>
      {/* {process.env.NODE_ENV !== "production" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      {process.env.NODE_ENV === "production" && (
        <FullStory org={String(process.env.WEB_FULLSTORY_KEY)} />
      )} */}
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;
