import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import store from './store';
import ReactDOM from 'react-dom';
import { ReactQueryConfigProvider } from 'react-query';
import { Provider } from 'react-redux';
import styled from 'styled-components';

const UnsecuredPageWarning = styled.h1`
  color: red;
`;

const Link = styled.a`
  text-decoration: none;
  color: red;
`;

const UnsecuredPage = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <div>
      <UnsecuredPageWarning>
        If you see this page, Web App link you have clicked on is under Clickjacking security attack.
      </UnsecuredPageWarning>
      <h2>Please inform team with the reference of the application from where you clicked this link.</h2>
      <h2>
        Click{' '}
        <Link href={window.self.location.href} title="Web Application" target="blank">
          here
        </Link>{' '}
        to access WebApp safely.
      </h2>
    </div>
  </div>
);

// Mock API on local development
if (process.env.NODE_ENV === 'development' && process.env.WEB_MOCK_API === 'true') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

if (window.self === window.top) {
  ReactDOM.render(
    <Provider store={store}>
      <ReactQueryConfigProvider
        config={{
          queries: {
            refetchOnWindowFocus: true,
          },
        }}
      >
        <App />
      </ReactQueryConfigProvider>
    </Provider>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(<UnsecuredPage />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
