import avatar from 'assets/avatar.png';
import blankImage from 'assets/blank-image.jpg';
import graphics from 'assets/graphics.png';
import collapseIn from 'assets/icons/Collapse-in.svg';
import collapseZoom from 'assets/icons/Collapse.png';
import history from 'assets/icons/History.svg';
import next_blue from 'assets/icons/Next-blue.svg';
import platforms from 'assets/icons/Platforms.svg';
import settings from 'assets/icons/Settings.svg';
import top_up from 'assets/icons/Topup.svg';
import wallet from 'assets/icons/Wallet.svg';
import withdrawal from 'assets/icons/Withdrawal.svg';
import add_more from 'assets/icons/add-more.svg';
import summary from 'assets/icons/amount-token.svg';
import amount from 'assets/icons/amount.svg';
import announcement from 'assets/icons/announcement.svg';
import apple from 'assets/icons/apple.svg';
import arrow_trending_down from 'assets/icons/arrow-trending-down.svg';
import arrow_trending_up from 'assets/icons/arrow-trending-up.svg';
import back from 'assets/icons/back.svg';
import bank_account from 'assets/icons/bank-account.svg';
import basic from 'assets/icons/basis.svg';
import black_logout from 'assets/icons/black_logout.svg';
import blue_tick from 'assets/icons/blue-tick.svg';
import cancel_icon from 'assets/icons/cancel.svg';
import category from 'assets/icons/category.svg';
import check_white from 'assets/icons/check_white.svg';
import child from 'assets/icons/child.svg';
import clipboard from 'assets/icons/clipboard-text.svg';
import clipboard_white from 'assets/icons/clipboard-white.svg';
import company from 'assets/icons/company.svg';
import confetti from 'assets/icons/confetti.png';
import confirm_icon from 'assets/icons/confirm.svg';
import create_account from 'assets/icons/create_account.svg';
import date from 'assets/icons/date.svg';
import delete_icon from 'assets/icons/delete.svg';
import drop_down from 'assets/icons/drop-down.svg';
import drop_up from 'assets/icons/drop-up.svg';
import edit_icon from 'assets/icons/edit.svg';
import error_found from 'assets/icons/error_found.svg';
import facebook from 'assets/icons/facebook.svg';
import fb from 'assets/icons/fb.svg';
import filter from 'assets/icons/filter.svg';
import financial_year from 'assets/icons/financial_year.svg';
import folder from 'assets/icons/folder.png';
import google from 'assets/icons/google.svg';
import green_tick from 'assets/icons/green-tick.svg';
import gray_tick from 'assets/icons/grey-tick.svg';
import helpCenter from 'assets/icons/help-center.svg';
import home from 'assets/icons/home.svg';
import infoBlue from 'assets/icons/info_blue.svg';
import logOut from 'assets/icons/log-out.svg';
import menu from 'assets/icons/menu.svg';
import news from 'assets/icons/news.svg';
import next_white from 'assets/icons/next-white.svg';
import next from 'assets/icons/next.svg';
import notification from 'assets/icons/notification.svg';
import attachment from 'assets/icons/paper-clip.svg';
import attach from 'assets/icons/paper-clip.svg';
import pdf from 'assets/icons/pdf.png';
import pending from 'assets/icons/pending.svg';
import price from 'assets/icons/price.svg';
import project from 'assets/icons/project.png';
import reference_no from 'assets/icons/reference-no.svg';
import refresh from 'assets/icons/refresh.svg';
import search from 'assets/icons/search.svg';
import see_more from 'assets/icons/see-more.svg';
import seft_service from 'assets/icons/seft-service.png';
import subcategory from 'assets/icons/subcategory.svg';
import success from 'assets/icons/success.svg';
import successful from 'assets/icons/successful.svg';
import swap from 'assets/icons/swap.svg';
import telegram from 'assets/icons/telegram.svg';
import token from 'assets/icons/token.svg';
import top_up_transaction from 'assets/icons/top-up-transactions.svg';
import twitter from 'assets/icons/twitter.svg';
import unsuccessful from 'assets/icons/unsuccessful.svg';
import uploadPdf from 'assets/icons/upload.png';
import upload from 'assets/icons/upload.svg';
import uploadIcon from 'assets/icons/user.svg';
import withdrawal_transaction from 'assets/icons/withdrawal-transactions.svg';
import john from 'assets/john.jpeg';
import logo_red from 'assets/logoKLDXRed.png';
import no_image from 'assets/noImage.jpg';
import loading from 'assets/svg/Loading.svg';
import close from 'assets/svg/close.svg';
import coupon from 'assets/svg/coupon.svg';
import dividend from 'assets/svg/dividend.svg';
import download_referral from 'assets/svg/download-referral.svg';
import download from 'assets/svg/download.svg';
import email from 'assets/svg/email.svg';
import infoGray from 'assets/svg/infoGrey.svg';
import infoWhite from 'assets/svg/infoWhite.svg';
import subscription from 'assets/svg/subscription.svg';
import verified from 'assets/svg/verified.svg';

export const IMAGES = {
  header: {
    notification,
    news,
    avatar,
    black_logout,
  },
  logo: {
    logo_red,
  },
  sidebar: {
    collapseZoom,
    collapseIn,
    platforms,
    settings,
    wallet,
    helpCenter,
    home,
    logOut,
    announcement,
    child,
    project,
    seft_service,
  },
  investorHomepage: {
    refresh,
    search,
    filter,
    no_image,
  },
  investorTopUp: {
    successful,
    unsuccessful,
    pending,
  },
  icon: {
    check_white,
    arrow_trending_down,
    arrow_trending_up,
    menu,
    pdf,
    success,
    top_up,
    withdrawal,
    history,
    error_found,
    drop_down,
    see_more,
    drop_up,
    next_blue,
    top_up_transaction,
    withdrawal_transaction,
    bank_account,
    add_more,
    close,
    email,
    verified,
    facebook,
    google,
    apple,
    infoWhite,
    infoGray,
    green_tick,
    gray_tick,
    blue_tick,
    subscription,
    clipboard,
    edit_icon,
    delete_icon,
    cancel_icon,
    confirm_icon,
    dividend,
    coupon,
    category,
    company,
    date,
    reference_no,
    subcategory,
    token,
    infoBlue,
    announcement,
    attachment,
    next,
    next_white,
    back,
    download,
    search,
    upload,
    create_account,
    swap,
    attach,
    clipboard_white,
    fb,
    telegram,
    twitter,
    download_referral,
    uploadIcon,
    financial_year,
    basic,
    price,
    amount,
    summary,
  },
  button: {
    loading,
  },
  images: {
    blankImage,
    confetti,
    folder,
    graphics,
    john,
    uploadPdf,
  },
};
