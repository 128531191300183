import type { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Auth, Hub } from 'aws-amplify';
import pick from 'lodash/pick';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { UI } from 'state/ui';
import { createContainer } from 'unstated-next';
import { parseBankInfo } from 'utils/bankAccount';
import { getRoleName } from 'utils/helpers';

declare global {
  interface Window {
    FS: any;
  }
}

export function useUser() {
  const { getSetting } = UI.useContainer();
  const [user, setUser] = useState<CognitoUserInterface | undefined>(undefined);
  const [cynopsisRecords, setCynopsisRecords] = useState<Array<Record<string, any>> | undefined>([]);
  const [userInfo, setUserInfo] = useState<
    | {
        user: Record<string, any>;
        customer?: Record<string, any>;
        profile: Record<string, any>;
        account?: Record<string, any>;
        permissions?: Array<string>;
        rootUser: Record<string, any>;
      }
    | undefined
  >(undefined);
  const [bankAccount, setBankAccount] = useState(parseBankInfo(''));
  const queryCache = useQueryCache();

  const resetUser = () => {
    setUser(undefined);
    setUserInfo(undefined);
    setCynopsisRecords(undefined);
  };

  const fetchFullProfile = useCallback(() => {
    fetch('/api/me', {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        setUserInfo(result.data);

        if (result.data.customer?.id) {
          // fetch bank info
          fetch(`/api/customer?id=${result.data.customer?.id}`, {
            headers: {
              accept: 'application/json',
              authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
              'x-artemis-domain': '1',
            },
          })
            .then((resp) => resp.json())
            .then((recordDetail) => {
              setCynopsisRecords(
                result.data?.customer?.customerType === 'INDIVIDUAL'
                  ? recordDetail?.data?.individualRecords ?? []
                  : recordDetail?.data?.corporateRecords ?? []
              );
            });
        }
      });
  }, [user]);

  // load profile after signed in
  useEffect(() => {
    // call user api
    if (user) {
      fetchFullProfile();
    }
  }, [user, fetchFullProfile]);

  const fetchUser = () => {
    Auth.currentAuthenticatedUser().then((user: CognitoUserInterface | undefined) => {
      // Identify Your Users for FullStory
      if (window.FS) {
        window.FS.identify(user?.username, {
          displayName: user?.attributes?.name,
          email: user?.attributes?.email,
          sub: user?.attributes?.sub,
        });
      }
      setUser(user);
    });
  };

  // check user authentication on rendering
  useEffect(() => {
    function handleUserAuthentication({ payload: { event, data } }: any) {
      switch (event) {
        case 'signIn':
          fetchUser();
          break;
        case 'signOut':
          setUser(undefined);
          break;
        default:
      }
    }
    Hub.listen('auth', handleUserAuthentication);

    fetchUser();

    return () => {
      Hub.remove('auth', handleUserAuthentication);
    };
  }, []);

  const userSettingsUrl = '/api/user/user/web-settings';
  const fetchUserSettings = useCallback(
    async (url) =>
      fetch(url, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': userInfo?.user?.user_api_key,
        },
      })
        .then((resp) => resp.json())
        .then((result) => result)
        .catch(),
    [userInfo?.user?.user_api_key]
  );

  useEffect(() => {
    if (!user) setUserInfo(undefined);
    const rootUser = userInfo?.rootUser ?? userInfo?.profile;
  }, [user, userInfo]);

  const TCFilename = getSetting('web_settings_latestTCFilenameVersion');

  const rootUser: Record<string, any> = userInfo?.rootUser ?? userInfo?.profile ?? {};

  return {
    user,
    resetUser,
    fetchUser,
    fetchUserSettings: () => queryCache.invalidateQueries(userSettingsUrl),
    bankAccount,
    setBankAccount,
    TCFilename,
    status: {
      userHasEnableMFA: () => user?.preferredMFA === 'SOFTWARE_TOKEN_MFA',
      TCAccepted: () => userInfo?.user?.userTcAccepted_accepted_filename === TCFilename,
      isCorporateMonitor: () => userInfo?.user?.account_type === 'corp-monitor',
      isCorporateTrader: () => userInfo?.user?.account_type === 'corp-trader',
      isCorporateAdmin: () => userInfo?.user?.account_type === 'corp-admin',
      isIndividual: () => userInfo?.user?.account_type === 'investors',
      canViewMultiAccounts: () =>
        userInfo?.user?.account_type === 'corp-admin' || userInfo?.user?.account_type === 'corp-monitor',
      canViewCorpHistory: () => userInfo?.permissions?.includes('WEB_ACCOUNT_SUBACCOUNT_HISTORY'),
      canTrade: () => userInfo?.permissions?.includes('WEB_ACCOUNT_TRADE'),
      canDeposit: () => userInfo?.permissions?.includes('WEB_ACCOUNT_DEPOSIT'),
      canWithdraw: () => userInfo?.permissions?.includes('WEB_ACCOUNT_WITHDRAW'),
      canAddUser: () => userInfo?.permissions?.includes('WEB_ACCOUNT_ADD_SUBACCOUNT'),
      canTransferAssets: () => userInfo?.permissions?.includes('WEB_ACCOUNT_TRANSFER_FUNDS'),
      canRequestQuote: () => userInfo?.permissions?.includes('WEB_ACCOUNT_RFQ'),
      canRetireToken: () => userInfo?.permissions?.includes('WEB_ACCOUNT_RETIREMENT'),
      canDeliverToken: () => userInfo?.permissions?.includes('WEB_ACCOUNT_PHYSICAL_DELIVERY'),
      accountType: () => userInfo?.user?.account_type?.toUpperCase(),
      isAccountActive: () => userInfo?.profile?.statusCode === 'ACTIVE',
    },
    selector: {
      getUserId: () => {
        return userInfo?.user?.user_id;
      },
      getUserRootId: () => {
        return userInfo?.user?.user_parent_id ?? userInfo?.user?.user_id;
      },
      isBankAccountSubmitted: () => {
        return !!bankAccount.accountIBAN;
      },
      isBankAccountApproved: () => {
        return !!bankAccount.accountIBAN && rootUser?.bankVerificationStatus === 'ACCEPTED';
      },
      isBankAccountRejected: () => {
        return !!bankAccount.accountIBAN && rootUser?.bankVerificationStatus === 'REJECTED';
      },
      getIBANAccount: () => {
        return bankAccount.accountIBAN.replace(/\d{4}(?=\d{4})/g, '****');
      },
      getUserProfile: () => {
        return {
          account: userInfo?.account?.accountId,
          cognito_id: userInfo?.profile?.cognitoId,
          email: userInfo?.profile?.cognitoId,
          cynopsisFullName: userInfo?.profile?.cynopsisFullName,
          first_name: userInfo?.user?.user_first_name,
          last_name: userInfo?.user?.user_last_name,
          account_type: userInfo?.user?.account_type,
          user_id: userInfo?.user?.user_id,
          user_name: userInfo?.user?.user_user_name,
        };
      },
      getCynopsisCustomerId: () => {
        return userInfo?.customer?.id;
      },
      getCynopsisAccountType: () => {
        return userInfo?.customer?.customerType;
      },
      getCynopsisCustomer: () => {
        return pick(userInfo?.customer?.customerType, [
          'domains',
          'isActiveCustomer',
          'natureOfBusinessRelationship',
          'onboardingMode',
          'paymentModes',
          'productServiceComplexity',
          'referenceId',
          'users',
        ]);
      },
      getFullName: () => {
        return userInfo?.profile?.cynopsisFullName && userInfo?.profile?.cynopsisFullName !== ''
          ? userInfo?.profile?.cynopsisFullName
          : `${userInfo?.user?.user_first_name ?? ''} ${userInfo?.user?.user_last_name ?? ''}`;
      },
      getTradingName: () => {
        return userInfo?.profile?.tradingName;
      },
      getAccountAddress: () => {
        return userInfo?.account?.accountId;
      },
      getUserApiKey: () => {
        return userInfo?.user?.user_api_key;
      },
      getKycStatus: () => {
        return rootUser?.kycStatus;
      },
      getCountryOfResidence: () => {
        return rootUser?.countryOfResidence;
      },
      getInvestorVerificationStatus: () => {
        return rootUser?.investorVerificationStatus;
      },
      getBankVerificationStatus: () => {
        return rootUser?.bankVerificationStatus ?? 'NEW';
      },
      getRoleName: () => {
        return getRoleName(userInfo?.user?.account_type);
      },
      kycProfileDetail: cynopsisRecords?.[0] ?? null,
    },
    fetchFullProfile,
  };
}

export const User = createContainer(useUser);
