import PageNotFound from './PageNotFound';
import { useAppDispatch } from 'hooks/reduxHook';
import useMqtt from 'hooks/useMqtt';
import Cookies from 'js-cookie';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { Redirect, Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import { setType } from 'store/ducks/system/slice';
import { LOCAL_STORAGE, PERMISSIONS, TYPE_LOGIN } from 'utils/constant';

type CustomRouteProps = RouteProps & { private?: boolean; pemisssion?: string };

export enum routesEnum {
  test = '/test',
  issuerBase = '/issuers/', // base
  investorBase = '/investors/',
  home = '/', // public
  signIn = '/issuers/sign-in', // public
  signUp = '/issuers/sign-up', // public
  signInSucess = '/issuers/sign-in-success',
  forgot = '/issuers/forgot-password', // public
  userPersonal = '/user-personal',
  issuerDashboard = '/issuer-dashboard',
  makePayment = '/make-payment',
  changePassword = '/change-password',
  indication = '/indication',
  viewProjectDetails = '/view-project-detail',
  baseUI = '/base-ui',
  headerMobile = '/header-mobile',
  forgotPasswordInvestor = '/investors/forgot-password',
  setPasswordCorporate = '/users/set-password',
  resetPassword = '/users/change-password',
  invetorPublishProject = '/investors/publish/project',
  investorDetailProject = '/investors/publish/project',
  // issuer
  issuerRegistrationFormBase = '/issuers/',
  issuerRegistrationForm = '/issuers/:tab',
  approveSmartContract = '/issuers/approve-smart-contract',
  requestChange = '/issuers/request-change',
  listInvestorSubcription = '/issuers/list-investor-subcription/:id',
  projectListing = '/issuers/project-listing',
  registerIssuerV2 = '/issuers/register/v2', // public
  issuerHomePage = '/issuers', // base

  //Omnibus
  setPasswordOmnibus = '/investors/verify-omnibus-corporate',

  //investor
  investorSignUp = '/investor-sign-up', // public
  investorSignUpV2 = '/investors/sign-up', // public
  investorSignUpWithSocial = '/investors/sign-up-with-social',
  yourInvestorSignUp = '/investors/sign-up-your-investor', // public
  investorSignUpCorporateInvestor = '/investors/sign-up-corporate-investor',
  riskProfileInvestor = '/investor-login/risk-profile-investor',
  investorLogin = '/investor-login', // public
  investorLoginV2 = '/investors/login', // public
  personalInformation = '/personal-information',
  personalDetail = '/investors/personal-detail-submit',
  completeInformation = '/investors/complete-information',
  messagePage = '/investors/message',
  dashboardMobile = '/investors/dashboard-mobile',
  projectInfo = '/investors/project-info',
  verifyPage = '/investors/verify',
  VerifyInvestorCorporate = '/investors/verify-investor-corporate',
  verifySuccessful = '/investors/success',
  kycVerify = '/investors/kyc-verify',
  kycSignUp = '/investors/kyc-sign-up',
  // investorProfile = '/investors-profile/:tab',
  investorIpay88 = '/investors/ipay88',
  investorMarket = '/investors/market',
  investorTopup = '/investors/top-up',
  investorPayment = '/investors/payment',
  investorTopUpResult = '/investors/topup-result',
  investorTopUpSignatureResult = '/investors/topup-result-signature',
  ctos = '/investors/ctos',
  investorDashboard = '/investors/dashboard',
  investorMessage = '/investors/message',
  investorSetting1 = '/investors/setting1',
  investorSetting = '/investors/setting',
  investorInfo = '/investors/info',
  detailProject = '/investors/project/:id',
  kycResponse = '/investors/kyc-result',
  kycResponseV2 = '/investors/kyc-result',
  investorTokenTransfer = '/investors/token-transfer',
  investorWithdrawal = '/investors/withdrawal',
  primaryListing = '/investors/primary-listing',
  primaryListingDetails = '/investors/primary-listing',
  platformTrading = '/investors/trading-platform',
  platformTradingDetails = '/investors/trading-platform',
  investorSubscribeIssuance = '/investors/subscribe-issuance',
  investorTransactions = '/investors/transactions',
  investorAccountStatement = '/investors/account-statement',
  investorHomePage = '/investors',
  investorActivities = '/investors/activities',
  investorTokenDetail = '/investors/token-detail',
  investorWallet = '/investors/wallet',
  investorPaymentToProcess = '/investors/payment-to-process',
  registrationInvestorCoprorate = '/investors/registration-investor-coprorate',
  tokenActivity = '/investors/token-activity',
  detailTokenActivity = '/investors/token-activity/detail',
  conversionActivity = '/investors/token-activity/conversion/:id',
  //announcementDetail
  investorAnnouncementList = '/investors/announcement-list',
  investorAnnouncement = '/investors/announcement-detail',
  issuerAnnouncement = '/investors/announcement',
  investorCoporateAccount = '/investors/coporate/account',
  investorCoporateCompanyInfo = '/investors/coporate/companyInfo',
  investorCoporateUserManagement = '/investors/coporate/userManagement',
  //individual
  completeInvestorInfo = '/investors/individual/investorInfo',
  accountMaintance = '/investors/individual/account',
  //childAccount
  accountManagement = '/investors/child-account/account-management',
  childAccountDetail = '/investors/child-account/account-management/:id',
  accountHoldings = '/investors/child-account/account-holdings',
  accountHoldingsTransfer = '/investors/child-account/account-holdings/transfer',
  notification = '/investors/child-account/notification',
  notificationDetail = '/investors/child-account/notification/details/:id',
  newNotification = '/investors/child-account/notification/new',
  editNotification = '/investors/child-account/notification/edit/:id',
  internalTransactions = '/investors/child-account/internal-transactions',
  detailSubscriptionOmnibus = '/investors/subscription-detail/:id',

  // referral
  referral = '/investors/referral',
  issuerReferral = '/issuer/referral',

  // issuer
  setPasswordIssuer = '/investor/set-password-issuer-onboarding',
  registrationIssuer = '/issuer/registration-issuer',
  accountMaintanceIssuer = '/issuer/account',
  completeIssuerInfo = '/issuer/issuerInfo',
  authorizedIssuerInfo = '/issuer/userManagement',
  documentIssuerInfo = '/issuer/documents',
  walletDetailIssuer = '/issuer/wallet-detail',
  transactionIssuer = '/issuer/transactions',
  myProjectDashboard = '/issuer/my-project',
  myProjectDetail = '/issuer/my-project',
  reporting = '/issuer/reporting-announcement',
  newSubmission = '/issuer/create-submission',
  submissionDetail = '/issuer/submission-detail',

  topUpCashPayment = '/issuer/top-up',
  topUpResult = '/issuer/topup-result',
  topUpResultSignature = '/issuer/topup-result-signature',
  documentForSecondaryAction = '/document-secondary/:secondaryId',
  withdrawalCashPayment = '/issuer/withdrawal',
}
const issuerRoute: CustomRouteProps[] = [
  {
    path: [routesEnum.makePayment, routesEnum.indication],
    exact: true,
    component: React.lazy(() => import('../components/IssuerLayout')),
  },
  {
    path: [routesEnum.approveSmartContract],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/ApproveSmartContract')),
  },
  // {
  //   path: [routesEnum.requestChange],
  //   exact: true,
  //   component: React.lazy(() => import('./RequestChange')),
  // },
  // {
  //   path: [routesEnum.viewProjectDetails],
  //   exact: true,
  //   component: React.lazy(() => import('./ViewProjectDetail')),
  // },
  {
    path: [routesEnum.projectListing],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/ProjectListing')),
  },
  {
    path: [routesEnum.issuerRegistrationForm],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/RegistrationForm')),
  },
  {
    path: [routesEnum.listInvestorSubcription],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ListSubcription/ListInvestorSubcription')),
  },
  {
    path: [routesEnum.issuerHomePage],
    exact: true,
    component: React.lazy(() => import('./HomePage')),
  },
  {
    path: [routesEnum.transactionIssuer],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/Transactions')),
  },
  {
    path: [routesEnum.registrationIssuer],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/RegistrationIssuer')),
  },
  {
    path: [routesEnum.accountMaintanceIssuer],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/AccountMaintance')),
  },
  {
    path: [routesEnum.completeIssuerInfo],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/CompleteIssuerInfo')),
  },
  {
    path: [routesEnum.authorizedIssuerInfo],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/CompleteIssuerInfo/UserManagement')),
  },
  {
    path: [routesEnum.walletDetailIssuer],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/Wallet')),
  },
  {
    path: [routesEnum.documentIssuerInfo],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/CompleteIssuerInfo/Documents')),
  },
  {
    path: [routesEnum.myProjectDashboard],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/MyProjectDashboard')),
  },
  {
    path: [`${routesEnum.myProjectDetail}/:id`],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/MyProjectDetail')),
  },
  {
    path: [routesEnum.reporting],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/ReportingAnnouncement')),
  },
  {
    path: [routesEnum.newSubmission],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/Submission/CreateSubmission')),
  },
  {
    path: [`${routesEnum.submissionDetail}/:id`],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/Submission/SubmissionDetail')),
  },

  {
    path: [routesEnum.issuerReferral],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ReferralManagement')),
  },
  {
    path: [routesEnum.withdrawalCashPayment],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/Withdrawal')),
  },
  {
    path: [routesEnum.topUpCashPayment],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/TopUp')),
  },
  {
    path: [`${routesEnum.topUpResult}/:refNo`],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/TopUpResult')),
  },
  {
    path: [`${routesEnum.topUpResultSignature}/:refNo`],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/TopUpResultSignature')),
  },
];

const publicRoute: CustomRouteProps[] = [
  {
    path: [routesEnum.baseUI],
    exact: true,
    component: React.lazy(() => import('./baseUI')),
  },
  {
    path: [routesEnum.invetorPublishProject],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/PublishProject')),
  },
  {
    path: [`${routesEnum.investorDetailProject}/:id`],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/DetailProjectPublish')),
  },
  // {
  //   path: [routesEnum.home],
  //   exact: true,
  //   component: React.lazy(() => import('./HomePage')),
  // },
  {
    path: [routesEnum.signIn, routesEnum.changePassword, routesEnum.signInSucess],
    exact: true,
    component: React.lazy(() => import('./SignIn')),
  },
  {
    path: [routesEnum.forgot],
    exact: true,
    component: React.lazy(() => import('./ForgotPassword')),
  },
  {
    path: [routesEnum.investorSignUp],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SignUp')),
  },
  {
    path: [routesEnum.investorSignUpV2, routesEnum.verifySuccessful],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SignUpV2')),
  },
  {
    path: [routesEnum.investorSignUpWithSocial],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SignUpWithSocial')),
  },
  {
    path: [routesEnum.yourInvestorSignUp],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SignUpYourInvestor')),
  },
  {
    path: [routesEnum.investorSignUpCorporateInvestor, routesEnum.verifySuccessful],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SignUpCorporateInvestor')),
  },
  {
    path: [routesEnum.investorLogin],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Login')),
  },
  {
    path: [routesEnum.investorLoginV2],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/LoginV2')),
  },
  {
    path: [routesEnum.signUp],
    exact: true,
    component: React.lazy(() => import('./SignUp')),
  },
  {
    path: [routesEnum.verifyPage],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/VerifyInvestor')),
  },
  {
    path: [routesEnum.VerifyInvestorCorporate],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/VerifyInvestorCorporate')),
  },
  {
    path: [routesEnum.test],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Test')),
  },
  {
    path: [routesEnum.headerMobile],
    exact: true,
    component: React.lazy(() => import('../components/CommonSidebar/FooterMobile')),
  },
  {
    path: [routesEnum.forgotPasswordInvestor],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ForgotPassword')),
  },
  {
    path: [routesEnum.setPasswordCorporate],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SetPasswordCorporate')),
  },
  {
    path: [routesEnum.setPasswordOmnibus],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SetPasswordOmnibus')),
  },
  {
    path: [routesEnum.setPasswordIssuer],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SetPasswordIssuerOnboarding')),
  },
  {
    path: [routesEnum.resetPassword],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ChangePassword')),
  },
  {
    path: [routesEnum.registerIssuerV2],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/RegistrationIssuerV2')),
  },
  {
    path: [routesEnum.documentForSecondaryAction],
    exact: true,
    component: React.lazy(() => import('./publicPage/ListDocumentForSecondaryAction')),
  },
];

const investorRoute: CustomRouteProps[] = [
  {
    path: [routesEnum.riskProfileInvestor],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/RiskProfile')),
  },

  {
    path: [routesEnum.personalInformation],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/PersonalInformation')),
  },
  // {
  //   path: [routesEnum.personalDetail],
  //   exact: true,
  //   component: React.lazy(() => import('./PersonalDetail')),
  // },
  {
    path: [routesEnum.completeInformation],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/StepByStep')),
  },
  // {
  //   path: [routesEnum.projectInfo],
  //   exact: true,
  //   component: React.lazy(() => import('./ProjectInfo')),
  // },

  {
    path: [routesEnum.kycVerify],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/KYCVerify')),
  },
  {
    path: [routesEnum.kycSignUp],
    exact: true,
    component: React.lazy(() => import('./IssuerPage/KYCSignUp')),
  },

  {
    path: [routesEnum.ctos],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/CTOS')),
  },
  {
    path: [routesEnum.investorIpay88],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/IPay88')),
  },
  {
    path: [`${routesEnum.investorPayment}/:projectId`],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Payment')),
  },
  {
    path: [`${routesEnum.investorTopUpResult}/:refNo`],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/TopUpResult')),
  },
  {
    path: [`${routesEnum.investorTopUpSignatureResult}/:refNo`],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/InvestorSignatureTopUpResult')),
  },
  {
    path: [routesEnum.investorMarket],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Market')),
  },
  {
    path: [routesEnum.investorTopup],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/TopUp')),
  },
  {
    path: [routesEnum.investorDashboard],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/DashboardV2')),
  },
  {
    path: [routesEnum.investorMessage],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Message')),
  },
  {
    path: [routesEnum.completeInvestorInfo],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/CompleteProfile')),
  },
  {
    path: [routesEnum.accountMaintance],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/CompleteProfile/components/AccountMaintenance')),
  },
  {
    path: [routesEnum.investorSetting1],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SettingV1')),
  },
  {
    path: [routesEnum.investorInfo],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Info')),
  },
  // {
  //   path: [routesEnum.detailProject],
  //   exact: true,
  //   component: React.lazy(() => import('./DetailProject')),
  // },
  {
    path: [routesEnum.kycResponse, routesEnum.kycResponseV2],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/KycResponse')),
  },
  {
    path: [routesEnum.investorTokenTransfer],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/TokenTransfer')),
  },
  {
    path: [routesEnum.investorWithdrawal],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Withdrawal')),
  },
  {
    path: [routesEnum.primaryListing],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/PrimaryListing')),
  },
  {
    path: [`${routesEnum.primaryListingDetails}/:id`],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/PrimaryListingDetails')),
  },
  {
    path: [routesEnum.platformTrading],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/PlatformTrading')),
  },
  {
    path: [`${routesEnum.investorSubscribeIssuance}/:id`],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SubscribeIssuance')),
  },
  {
    path: [routesEnum.investorTransactions],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Transactions')),
  },
  {
    path: [routesEnum.investorAccountStatement],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/AccountStatement')),
  },
  {
    path: [routesEnum.investorActivities],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Activities')),
  },
  {
    path: [routesEnum.investorTokenDetail],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/TokenDetail')),
  },
  {
    path: [routesEnum.investorWallet],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Wallet')),
  },
  {
    path: [routesEnum.investorAnnouncementList],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/AnnouncementList')),
  },
  {
    path: [`${routesEnum.investorAnnouncement}/:id`],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/AnnouncementDetail')),
  },
  {
    path: [routesEnum.investorPaymentToProcess],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/PaymentToProcess')),
  },
  {
    path: [routesEnum.registrationInvestorCoprorate],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/RegistrationInvestorCoprorate')),
  },
  {
    path: [routesEnum.accountManagement],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ChildAccount/AccountManagement')),
  },
  {
    path: [routesEnum.childAccountDetail],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ChildAccountDetail')),
  },
  {
    path: [routesEnum.accountHoldings],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/AccountHoldings')),
  },
  {
    path: [routesEnum.accountHoldingsTransfer],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/AccountHoldingsTransfer')),
  },
  {
    path: [routesEnum.notification],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ChildAccount/Notification')),
  },
  {
    path: [routesEnum.notificationDetail],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ChildAccount/Notification/components/NotificationDetail')),
  },
  {
    path: [routesEnum.newNotification],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ChildAccount/Notification/components/NewNotification')),
  },
  {
    path: [routesEnum.editNotification],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ChildAccount/Notification/components/EditNotification')),
  },
  {
    path: [routesEnum.detailSubscriptionOmnibus],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/SubscriptionDetail')),
  },
  {
    path: [routesEnum.internalTransactions],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ChildAccount/InternalTransactions')),
  },
  {
    path: [routesEnum.tokenActivity],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/TokenActivity')),
  },
  {
    path: [`${routesEnum.detailTokenActivity}/:id`],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/TokenActivityDetail')),
  },
  {
    path: [routesEnum.conversionActivity],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ConversionActivity')),
  },
  {
    path: [routesEnum.referral],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/ReferralManagement')),
  },
  {
    path: [routesEnum.investorAnnouncement],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/AnnouncementDetail')),
  },
  {
    path: [routesEnum.issuerAnnouncement],
    exact: true,
    component: React.lazy(() => import('./InvestorPage/IssuerAnnouncement')),
  },
  {
    path: [routesEnum.investorHomePage],
    exact: true,
    component: React.lazy(() => import('././HomePage')),
  },
  {
    path: [routesEnum.investorCoporateAccount],
    pemisssion: PERMISSIONS.WEB_INVESTOR_CORP_MANAGE,
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Corporate/Account')),
  },
  {
    path: [routesEnum.investorCoporateCompanyInfo],
    pemisssion: PERMISSIONS.WEB_INVESTOR_CORP_MANAGE,
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Corporate/CompanyInfo')),
  },
  {
    path: [routesEnum.investorCoporateUserManagement],
    pemisssion: PERMISSIONS.WEB_INVESTOR_CORP_MANAGE,
    exact: true,
    component: React.lazy(() => import('./InvestorPage/Corporate/UserManagement')),
  },
];

export const Routes: FC = () => {
  const location = useLocation();
  useMqtt();

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location]);
  const token = Cookies.get('token');
  const dispatch = useAppDispatch();

  if (!token) {
    localStorage.clear();
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    sessionStorage.clear();
    dispatch(setType(''));
  }

  const isInvestor = localStorage.getItem(LOCAL_STORAGE.type) === TYPE_LOGIN.investor;
  const isIssuer = localStorage.getItem(LOCAL_STORAGE.type) === TYPE_LOGIN.issuer;

  const permissions = localStorage.getItem(LOCAL_STORAGE.user)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.user) || '')?.permissions
    : [];

  return (
    <Switch>
      {publicRoute.map((route) => (
        <Route path={route.path} key={route.path as string} exact={route.exact} component={route.component} />
      ))}
      {isIssuer &&
        issuerRoute.map((route) => (
          <Route path={route.path} key={route.path as string} exact={route.exact} component={route.component} />
        ))}
      {isInvestor &&
        investorRoute.map((route) => {
          if (route.pemisssion && !permissions.includes(route.pemisssion)) {
            return <Redirect from="/" to={routesEnum.investorHomePage} />;
          }
          return <Route path={route.path} key={route.path as string} exact={route.exact} component={route.component} />;
        })}
      {isInvestor && <Redirect from="/" to={routesEnum.investorHomePage} />}
      {isIssuer && <Redirect from="/" to={routesEnum.issuerHomePage} />}
      <Route path="*">
        {!isInvestor && !isIssuer ? (
          <Redirect to={`${routesEnum.investorLoginV2}?url=${location.pathname}${location.search}`} />
        ) : (
          <PageNotFound />
        )}
      </Route>
    </Switch>
  );
};
