import {
  BankAccountInfo,
  CreateActor,
  CreatePepDeclaration,
  IAnnouncementTokenholder,
  IRequestAddCompanyShareHolder,
  IRequestAddIndividualShareHolder,
  IRequestUpdateCompanyProfile,
  IRequestUpdateProjectSubCription,
  IResolutionRequest,
  IResolutionRequestUpdate,
  ISetPasswordAuthorizedRequest,
  IVerifyAuthorizedPesonToken,
  VerifyWithDrawal,
} from './types';
import { axiosInstance } from 'api/axios';

export const updateIssuerCompanyProfile = async (request: IRequestUpdateCompanyProfile): Promise<any> => {
  const response = await axiosInstance.post(`/issuer/companyProfile`, request);
  return response;
};

export const addIssuerShareholderCompany = async (request: IRequestAddCompanyShareHolder): Promise<any> => {
  const response = await axiosInstance.post(`/issuer/shareholders/company`, request);
  return response;
};

export const updateIssuerShareholderCompany = async (request: IRequestAddCompanyShareHolder): Promise<any> => {
  const response = await axiosInstance.put(`/issuer/shareholders/company`, request);
  return response;
};

export const addIssuerShareholderIndividual = async (request: IRequestAddIndividualShareHolder): Promise<any> => {
  const response = await axiosInstance.post(`/issuer/shareholders/individual`, request);
  return response;
};

export const updateIssuerShareholderIndividual = async (request: IRequestAddIndividualShareHolder): Promise<any> => {
  const response = await axiosInstance.put(`/issuer/shareholders/individual`, request);
  return response;
};

export const upIssuerApplication = async (): Promise<any> => {
  const response = await axiosInstance.post(`/issuer/application`);
  return response;
};

export const submitIssuerDocument = async (request: FormData): Promise<any> => {
  const response = await axiosInstance.post(`/issuer/registrationDocument`, request);
  return response;
};

export const updateProjectSubcription = async (request: IRequestUpdateProjectSubCription): Promise<any> => {
  const response = await axiosInstance.post(`/issuer/updateStatusProjectSubscription`, request);
  return response;
};

export const verifyResetPasswordToken = async (request: IVerifyAuthorizedPesonToken): Promise<any> => {
  const { data } = await axiosInstance.post(`/issuer/verify-authorized-person-token`, request);
  return data;
};

export const setPasswordAuthorizedIssuer = async (request: ISetPasswordAuthorizedRequest): Promise<any> => {
  const data = await axiosInstance.post(`/issuer/change-password-first-time`, request);
  return data;
};

export const getCompanyDetailIssuer = async (): Promise<any> => {
  const { data } = await axiosInstance.get(`/issuer/company-detail`);
  return data;
};

export const submitCompanyDetailIssuer = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/issuer/profile`, request);
  return response;
};

export const createIssuerActor = async (request: CreateActor): Promise<any> => {
  const response = await axiosInstance.post(`/issuers/actors/create`, request);
  return response;
};

export const updateIssuerActor = async (request: any): Promise<any> => {
  const { actorId, ...rest } = request.actorInfo;

  const response = await axiosInstance.put(`/issuers/actors/update/${actorId}`, { actorInfo: rest });
  return response;
};

export const deleteIssuerActor = async (actorId: number): Promise<any> => {
  const response = await axiosInstance.delete(`/issuers/actors/delete/${actorId}`);
  return response;
};

export const saveAllActor = async (): Promise<any> => {
  const response = await axiosInstance.post(`/issuers/actors/save-all-actors`);
  return response;
};

export const saveListDocumentsIssuer = async (body: any): Promise<any> => {
  const data = await axiosInstance.post(`/issuers/documents`, body);
  return data;
};

export const getCompanySummary = async (): Promise<any> => {
  const { data } = await axiosInstance.get(`/issuer2/summary`);
  return data;
};

export const createIssuerPepDeclaration = async (request: CreatePepDeclaration): Promise<any> => {
  const response = await axiosInstance.post(`/issuer2/pep-declaration`, request);
  return response;
};
export const createIssuerRelationship = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/issuer2/reletionship-shareholder`, request);
  return response;
};
export const updateIssuerPepDeclaration = async (request: CreatePepDeclaration): Promise<any> => {
  const { id, ...rest } = request;
  const response = await axiosInstance.put(`/issuer2/pep-declaration/${id}`, rest);
  return response;
};
export const updateIssuerRelationship = async (request: any): Promise<any> => {
  const { id, ...rest } = request;
  const response = await axiosInstance.put(`/issuer2/reletionship-shareholder/${id}`, rest);
  return response;
};
export const deletePepDeclaration = async (pepId: number): Promise<any> => {
  const response = await axiosInstance.delete(`/issuer2/pep-declaration/${pepId}`);
  return response;
};
export const deleteRelationship = async (relationId: number): Promise<any> => {
  const response = await axiosInstance.delete(`/issuer2/reletionship-shareholder/${relationId}`);
  return response;
};
export const getListIssuerDeclaration = async (): Promise<any> => {
  const { data } = await axiosInstance.get(`/issuer2/pep-declaration`);
  return data;
};
export const getListIssuerRelationship = async (): Promise<any> => {
  const { data } = await axiosInstance.get(`/issuer2/reletionship-shareholder`);
  return data;
};
export const createApplicantDocument = async (request: { documentId: number }): Promise<any> => {
  const response = await axiosInstance.post(`/issuer2/applicant-declaration`, request);
  return response;
};

export const updateApplicantDocument = async (request: {
  documentId: number;
  remark: string;
  id: number;
}): Promise<any> => {
  const { id, ...rest } = request;
  const response = await axiosInstance.put(`/issuer2/applicant-declaration/${request.id}`, rest);
  return response;
};

export const deleteApplicantDocument = async (id: number): Promise<any> => {
  const response = await axiosInstance.delete(`/issuer2/applicant-declaration/${id}`);
  return response;
};

export const getListApplicantDocument = async (): Promise<any> => {
  const { data } = await axiosInstance.get(`/issuer2/applicant-declaration`);
  return data;
};

export const saveAllPepDeclaration = async (request: {
  isPepRelated: boolean;
  isRelationShipShareholder?: boolean;
}): Promise<any> => {
  const response = await axiosInstance.post(`/issuer2/pep-declaration/save-all`, request);
  return response;
};

export const submitSummaryIssuer = async (): Promise<any> => {
  const response = await axiosInstance.put(`/issuer2/summary`);
  return response;
};
export const deleteSubmission = async (id: number): Promise<any> => {
  const response = await axiosInstance.delete(`/submissions/${id}`);
  return response;
};
export const createSubmissions = async (request: any): Promise<any> => {
  const response = await axiosInstance.post(`/submissions`, request);
  return response;
};
export const updateSubmissions = async (request: any): Promise<any> => {
  const response = await axiosInstance.put(`/submissions/${request.id}`, request);
  return response;
};
export const submitSubmission = async (id: string | number): Promise<any> => {
  const response = await axiosInstance.put(`/submissions/${id}/submit`);
  return response;
};
export const changeStatusAuthorizedPersonIssuer = async (request: any): Promise<any> => {
  const body = {
    action: request?.action,
  };
  return await axiosInstance.put(`/issuers/actors/update-status/${request?.id}`, body);
};
export const userViewNotification = async (request: { id: string }): Promise<any> => {
  const { data } = await axiosInstance.post(`/user/clicking-view-notification`, request);
  return data;
};
export const submitBankAccountInfo = async (request: BankAccountInfo): Promise<any> => {
  const { data } = await axiosInstance.post(`/issuers/bank-account`, request);
  return data;
};
export const downloadCashAccountPDF = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/issuers/wallet/transactions/download-PDF-cash`, request, {
    responseType: 'arraybuffer',
  });
  return data;
};
export const downloadAllStatements = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/issuers/transactions/download-all-statement`, request, {
    responseType: 'arraybuffer',
  });
  return data;
};

export const verifyIssuerWithdrawal = async (request: VerifyWithDrawal): Promise<any> => {
  const { data } = await axiosInstance.post(`/issuers/withdrawal`, request);
  return data;
};
export const downloadIssuerWithdrawPDF = async (request: any): Promise<any> => {
  const data = await axiosInstance.post(`/issuers/wallet/withdraw/download-PDF`, request, {
    responseType: 'arraybuffer',
  });
  return data;
};

export const createAnnouncementResolution = async (request: IResolutionRequest): Promise<any> => {
  const response = await axiosInstance.post(`/submissions/token-holder-meeting/resolution`, request);
  return response;
};

export const updateAnnouncementResolution = async (request: IResolutionRequestUpdate): Promise<any> => {
  const response = await axiosInstance.post(`/submissions/token-holder-meeting/resolution/update`, request);
  return response;
};

export const deleteAnnouncementResolution = async (request: IResolutionRequest, id: number): Promise<any> => {
  const response = await axiosInstance.post(`/submissions/token-holder-meeting/resolution/delete/${id}`, request);
  return response;
};

export const createAnnouncementTokenholder = async (request: IAnnouncementTokenholder): Promise<any> => {
  const response = await axiosInstance.post(`/submissions/tokenholder-meeting`, request);
  return response;
};
