import { IMetadata, ISelectBoxProps } from './commonType';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { PERMISSIONS } from 'utils/constant';
import { IUser } from 'api/user';

export const onDownload = (blob: any, fileName: any) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  const title = fileName === '' ? `CSV Report` : fileName;

  link.href = url;
  link.setAttribute('download', `${title} - created ${new Date().toDateString()}.csv`);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadZipFile = (res: any, fileName: string, isView?: boolean) => {
  const data = URL.createObjectURL(new Blob([res], { type: 'application/zip' }));
  const link = document.createElement('a');
  link.href = data;
  if (!isView) {
    link.setAttribute('download', fileName);
  }
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};
export const downloadImage = (linkDownload: string) => {
  const link = document.createElement('a');
  link.href = linkDownload;
  link.download = 'Download_image';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const themes = {
  BRAND1: {
    brandColor: '#2CAB77',
    brandRbgColor: '44,171,119',
    backgroundColor: '#191919',
    secondaryBackgroundColor: '#f2f2f2',
    secondaryColor: '#ededed',
    hxBackgroundColor: '#000',
  },
  BRAND2: {
    brandColor: '#E7313A',
    brandRbgColor: '231,49,58',
    backgroundColor: '#191919',
    secondaryBackgroundColor: '#f2f2f2',
    secondaryColor: '#ededed',
    hxBackgroundColor: '#FFFFFF',
  },
};

export function getTheme(companyName: 'BRAND2' | 'BRAND1'): {
  brandColor: string;
  brandRbgColor: string;
} {
  return themes[companyName];
}

export default {
  themes,
  getTheme,
};

export function timestampToUtc(timestamp: number) {
  return new Date(timestamp * 1000).toUTCString();
}

export const addCommas = (num: number | string) => {
  const str = num?.toString();

  const hasDecimal = str?.includes('.');

  if (!hasDecimal) {
    return str?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const [integerPart, decimalPart] = str?.split('.');
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${formattedIntegerPart}.${decimalPart}`;
};

export const removeNonNumeric = (num: number | string) => num?.toString().replace(/[^0-9.]/g, '');

export const transformArray = (arrayData: any) => (Array.isArray(arrayData) ? arrayData : []);

export const handleListDataSelectBox = (listData: IMetadata[]) => {
  const data: ISelectBoxProps[] = [];
  data.push({ value: '', label: 'Please select' });
  for (let i = 0; i < listData.length; i++) {
    data.push({ value: listData[i].code, label: listData[i].label });
  }
  return data;
};

export const handleListDataSelectBoxWithout = (listData: IMetadata[]) => {
  const data: ISelectBoxProps[] = [];
  for (let i = 0; i < listData.length; i++) {
    data.push({ value: listData[i].code, label: listData[i].label });
  }
  return data;
};

export const convertTwoDecimal: (value: string) => string = (value: string) => {
  const numericValue = value.replace(/[^0-9.]/g, '');
  const limitedDecimalValue = numericValue.replace(/(\.\d{2})\d+?$/, '$1');

  return limitedDecimalValue;
};

export const changeToTimeUTC: (value: string) => string = (time: string) => {
  const localTime = time;
  const localMoment = moment(localTime, 'HH:mm');
  const utcMoment = localMoment.utcOffset(0);
  const utcTime = utcMoment.format('HH:mm');
  return utcTime;
};

export const handleFileUpload = (e: any) => {
  const file = e.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
    if (e.target?.result) {
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    }
  };
  reader.readAsArrayBuffer(file);
};

export const generateUserId = (userData: IUser) => {
  if (!userData || !userData.id) return ''

  const permissions = userData?.permissions ?? []
  const isOmnibusAcc = permissions?.includes(PERMISSIONS.WEB_OMNIBUS_AUTHORIZE)
  const isCorporateAcc = permissions?.includes(PERMISSIONS.WEB_INVESTOR_CORP_MANAGE)

  if (isOmnibusAcc || isCorporateAcc) return userData?.userId?.toString()

  return userData?.id?.toString()
}