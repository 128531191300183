import styled from 'styled-components';

interface IDrawer {
  children: any;
  isOpen: Boolean;
  setIsOpen: any;
}
const Wrapper = styled.section`
  top: 86px; 
  @media (max-width: 767px) {
    top: 155px; 
  }
  @media (max-width: 650px) {
    top: 112px; 
  }
`;

export default function Drawer(props: IDrawer) {
  const { children, isOpen, setIsOpen } = props;
  return (
    <div>
      <main
        className={
          ' fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ' +
          (isOpen
            ? ' transition-opacity opacity-100 duration-500 translate-x-0  '
            : ' transition-all delay-500 opacity-0 translate-x-full  ')
        }
      >
        <Wrapper
          className={
            ' w-screen max-w-lg right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ' +
            (isOpen ? ' translate-x-0 ' : ' translate-x-full ')
          }
        >
          <article className="relative w-screen max-w-lg pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
            {children}
          </article>
        </Wrapper>
        <section
          className=" w-screen h-full cursor-pointer "
          onClick={() => {
            setIsOpen(false);
          }}
        ></section>
      </main>
    </div>
  );
}
