export enum formatOptions {
    decimalOne = 1,
    decimalTwo = 2,
    decimalThree = 3,
    zero = 0,
    ten = 10,
    fifty = 50
}

export enum PrecisionOptions {
    decimalZero,
    decimalOne,
    decimalTwo,
    decimalThree,
    decimalFour,
    decimalFive,
    decimalSix,
    decimalSeven,
    decimalEight,
    decimalNine
  }

export interface IFormatDecimalProps {
    inputNumber: number
    formatOption: formatOptions | PrecisionOptions
}

export interface IFormatPrice extends IFormatDecimalProps{}

export interface IFormatPrecision {
    inputNumber: number
    formatOption: PrecisionOptions
  }

export interface IOption {
  value: string
  label: string
}

export const DATE_CONSTANTS = {
  MONTH_DAY_YEAR: 'MM-DD-YYYY',
  SHORT: 'DD/MM/YYYY HH:mm',
  LONG: 'DD MMM YYYY HH:mm',
  LONG_SS: 'DD MMM YYYY HH:mm:ss',
  LONG_F_SS: 'DD/MM/YYYY HH:mm:ss',
  YMD: 'YYYY-MM-DD',
  DMY: 'DD-MM-YYYY',
  HOUR_MINUTE: 'HH:mm',
  MDoY: 'MMM Do YY',
  DMY_HMA: 'DD MMM YYYY hh:mm A',
  DMY_MAIN_HMA: 'DD/MM/YYYY hh:mm A',
  MDY: 'MMM DD, YYYY',
  DMY_F_SLASH: 'DD/MM/YYYY',
  MDY_F_SLASH: 'MM/DD/YYYY',
  DMY_hMA: 'DD MMM YYYY h:mm A'
}

export const TOKENDEFAULT = {
  RM:'RM',
}