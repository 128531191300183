import Header from 'components/CommonSidebar/Header';
import FooterMobile from '../CommonSidebar/FooterMobile';

import AnnouncementDrawer from 'components/InvestorCommon/Drawable/AnnouncementDrawer';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from 'components/CommonSidebar/Sidebar';
import HeaderMobile from 'components/CommonSidebar/HeaderMobile';
import SidebarMoblie from 'components/CommonSidebar/SidebarMobile';

const Wrapper = styled.div`
  margin-top: 88px;
  .collapse {
    margin-left: 290px;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }
  .no-collapse {
    margin-left: 50px;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }
`;

const WrapperChildren = styled.div`
  @media (max-width: 767px) {
    margin-top: 4rem;
  }
`;

const Content = styled.div<any>`
  background: var(--bg-filled-color);
  min-height: calc(100vh - 85px);
  margin-top: 85px;
  padding: 0 40px 0 60px;

  @media screen and (max-width: 1600px) {
    padding: 0 40px 0 40px;
    ${(props) =>
      !props.collapsed &&
      `
        padding: 0 40px 0 60px;
    `}
  }
`;

const InvestorDashBoard: FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
    }
    if (window.innerWidth > 768) {
      setCollapsed(true);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (
    <div className="" style={{ fontFamily: 'Lato' }}>
      {/* <ApolloProvider client={apolloClient}> */}
      {windowSize > 768 ? (
        <div>
          <Header showAnnouncement={showAnnouncement} setShowAnnouncement={setShowAnnouncement} />
          <Wrapper className="">
            <Sidebar menuCollapse={!collapsed} setMenuCollapse={handleCollapsedChange} />
            <div>
              <AnnouncementDrawer showAnnouncement={showAnnouncement} setShowAnnouncement={setShowAnnouncement} />
            </div>
            <Content
              collapsed={collapsed}
              className={`flex flex-col h-full  bg-gray-200 ${!collapsed ? 'no-collapse' : 'collapse'}`}
            >
              {children}
            </Content>
          </Wrapper>
        </div>
      ) : (
        <div>
          <HeaderMobile
            setMenuCollapse={handleCollapsedChange}
            setShowAnnouncement={setShowAnnouncement}
            showAnnouncement={showAnnouncement}
          />
          <SidebarMoblie menuCollapse={!collapsed} setMenuCollapse={handleCollapsedChange} />
          <div>
            <AnnouncementDrawer showAnnouncement={showAnnouncement} setShowAnnouncement={setShowAnnouncement} />
          </div>

          <WrapperChildren className="my-5 sm:my-10 px-3 pb-20 md:px-0">{children}</WrapperChildren>

          <FooterMobile />
        </div>
      )}
      {/* </ApolloProvider> */}
    </div>
  );
};

export default InvestorDashBoard;
