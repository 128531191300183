import InvestorLayout from 'components/InvestorLayout';
import IssuerLayout from 'components/IssuerLayout';
import { useAppSelector } from 'hooks/reduxHook';
import { routesEnum } from 'pages/Routes';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { getType } from 'store/ducks/system/slice';
import { TYPE_LOGIN } from 'utils/constant';

export const RenderLayout: React.FC = ({ children }) => {
  const type = useAppSelector(getType);
  const location = useLocation();

  const publishRouter = [
    routesEnum.investorSignUpV2,
    routesEnum.forgot,
    routesEnum.verifySuccessful,
    routesEnum.investorLoginV2,
    routesEnum.invetorPublishProject,
    `${routesEnum.investorDetailProject}/:id`,
    routesEnum.signIn,
    routesEnum.changePassword,
    routesEnum.signInSucess,
    routesEnum.investorSignUp,
    routesEnum.investorSignUpWithSocial,
    routesEnum.verifySuccessful,
    routesEnum.forgotPasswordInvestor,
    routesEnum.resetPassword,
    routesEnum.yourInvestorSignUp,
    routesEnum.investorSignUpCorporateInvestor,
    routesEnum.setPasswordIssuer,
  ] as string[];

  if (type === TYPE_LOGIN.investor && !publishRouter.includes(location.pathname)) {
    return <InvestorLayout>{children}</InvestorLayout>;
  }
  if (type === TYPE_LOGIN.issuer  && !publishRouter.includes(location.pathname)) {
    return <IssuerLayout>{children}</IssuerLayout>;
  }
  return (
    <>
      <>{children}</>
    </>
  );
};
