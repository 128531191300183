/* eslint-disable @typescript-eslint/no-unused-vars */
import mqtt, { IClientOptions, ISubscriptionGrant, MqttClient } from 'mqtt';

function getClient(
  url: string,
  options?: IClientOptions,
  errorHandler?: (err: Error) => void
): Promise<MqttClient | null> {
  return new Promise((resolve, reject) => {
    const client = mqtt.connect(url, options ?? {});

    client.on('connect', () => {
      resolve(client);
    });

    client.stream.on('error', (err) => {
      errorHandler && errorHandler(err);
      client.end();
      reject(null);
    });
  });
}
function subscribe(client: MqttClient, topic: string, errorHandler?: (err: Error) => void) {
  const callBack = (err: Error | null, granted?: ISubscriptionGrant[]) => {
    if (err) {
      errorHandler && errorHandler(err);
    }
  };
  return client.subscribe(topic, callBack);
}
function onMessage(client: MqttClient, currentTopic: string, callBack: (payload: any) => void) {
  client.on('message', (topic: string, message: any, packet: any) => {
    if (topic !== currentTopic) return;
    const payload = {
      topic,
      packet,
      message: JSON.parse(new TextDecoder('utf-8').decode(message) || ''),
    };

    callBack(payload);
  });
}
function unsubscribe(client: MqttClient, topic: string) {
  client.unsubscribe(topic);
}
function closeConnection(client: MqttClient) {
  client.end();
}

function publish(client: MqttClient, topic: string, message: string) {
  client.publish(topic, message);
}

export const mqttService = {
  getClient,
  subscribe,
  onMessage,
  unsubscribe,
  closeConnection,
  publish,
};
