import { ContainerTab } from './Styled';
import React from 'react';
import { Text } from '../TextV2';

interface Props {
  title: string;
  isChoice: boolean;
  onClick: () => void;
}

function Tab(props: Props) {
  const { title = '', isChoice, onClick } = props;

  return (
    <ContainerTab onClick={onClick} isChoice={isChoice}>
      <Text level={3} color="#5B5D6B">{title}</Text>
    </ContainerTab>
  );
}
export default Tab;
