import { UI } from './ui';
import { User } from './user';
import abiDecoder from 'abi-decoder';
import useBeforeUnload from 'hooks/useBeforeUnload';
import useQueryParams from 'hooks/useQueryParams';
import { useEffect, useCallback, useRef, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useEffectReducer, EffectReducer } from 'use-effect-reducer';
import { getAddressData } from 'utils/contractGetters';
import { convertUnitValue } from 'utils/convert';
import { hex2int } from 'utils/formatter';
import Web3 from 'web3';
import { HttpProvider, HttpProviderOptions } from 'web3-providers';

const { WEB_INDEXER_API } = process.env;

declare global {
  interface Window {
    reportJSON: Record<string, any>;
    amILoadedYet: () => boolean;
  }
}

type Action =
  | {
      type: 'INIT';
      payload: {
        initState: Partial<State>;
        contractJson: Record<string, any>;
        providerUrl: string;
      };
    }
  | { type: 'CHANGE_PAGE'; payload: { page: number; limit: number | string } }
  | { type: 'FILTER_BY_EVENTS'; payload: { events: Array<string> } }
  | { type: 'FILTER_BY_TOKENS'; payload: { tokenTypes: Array<string> } }
  | {
      type: 'FILTER_BY_DATE';
      payload: {
        startDate?: Date | null | undefined;
        endDate?: Date | null | undefined;
      };
    }
  | {
      type: 'LOAD_ACCOUNTS';
      payload: {
        accounts: Array<{
          account: string;
          name: string;
        }>;
      };
    };

type FilterBy = {
  range?: {
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
  };
  eventNames?: Array<string>;
  tokenTypes?: Array<string>;
  address?: string;
  page: number;
  limit: number | string;
};

type State = {
  tokenUnit: string;
  ccys: Record<string, any>;
  activeCurrencies: string;
  tokenTypes: Record<string, any>;
  activeTokens: string;
  accountsNames: Record<string, string>;
  filterBy: FilterBy;
  contractJson: Record<string, any>;
  providerUrl: string;
};

const initState: State = {
  tokenUnit: '',
  ccys: {},
  activeCurrencies: '',
  tokenTypes: {},
  activeTokens: '',
  accountsNames: {},
  filterBy: {
    page: 1,
    limit: 30,
  },
  contractJson: {},
  providerUrl: '',
};

const reducer: EffectReducer<State, Action> = (state: State, action: Action) => {
  switch (action.type) {
    case 'INIT': {
      return {
        ...initState,
        ...action.payload.initState,
        contractJson: action.payload.contractJson,
        providerUrl: action.payload.providerUrl,
      };
    }
    case 'LOAD_ACCOUNTS': {
      if (action.payload.accounts?.length) {
        const accountNames = action.payload.accounts.reduce((accounts, nextAccount) => {
          return { ...accounts, [nextAccount.account]: nextAccount.name };
        }, {});

        return {
          ...state,
          accountsNames: {
            ...state.accountsNames,
            ...accountNames,
          },
        };
      }

      return state;
    }
    case 'CHANGE_PAGE': {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          ...action.payload,
        },
      };
    }

    case 'FILTER_BY_DATE': {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          range: action.payload,
          // reset to 1st page
          page: 1,
        },
      };
    }

    case 'FILTER_BY_EVENTS': {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          eventNames: action.payload.events,
          // reset to 1st page
          page: 1,
        },
      };
    }

    case 'FILTER_BY_TOKENS': {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          tokenTypes: action.payload.tokenTypes,
          // reset to 1st page
          page: 1,
        },
      };
    }

    default:
      return state;
  }
};

export function useContract(initFilter: FilterBy | undefined) {
  const {
    user,
    selector: { getAccountAddress },
  } = User.useContainer();
  const { settings, getAccountNames, getSetting, blockchainView } = UI.useContainer();
  const query = useQueryParams();

  const deployedContractRef = useRef<any>();
  const web3HttpRef = useRef<Web3>();
  const web3WsRef = useRef<Web3>();

  const [state, dispatch] = useEffectReducer(reducer, initState, {});
  const [txTypes, setTxTypes] = useState<Array<Record<string, string>>>([]);

  const changeUrl = ({
    startDate,
    endDate,
    eventNames,
    tokenTypes,
    page,
    limit,
  }: {
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
    eventNames?: string | null | undefined;
    tokenTypes?: string | null | undefined;
    page: number;
    limit: number | string;
  }) => {
    if (eventNames) query.set('eventNames', eventNames);
    else if (eventNames === '') query.delete('eventNames');
    if (tokenTypes) query.set('tokenTypes', tokenTypes);
    else if (tokenTypes === '') query.delete('tokenTypes');
    if (page) query.set('page', page.toString());
    if (limit) query.set('limit', limit.toString());
    if (startDate) query.set('startDate', startDate?.toISOString() ?? '');
    else query.delete('startDate');
    if (endDate) query.set('endDate', endDate?.toISOString() ?? '');
    else query.delete('endDate');
    const url = new URL(window.location.href);
    url.search = query.toString();
    window.history.pushState(query.toString(), document.title, url.toString());
  };

  // actions
  const changePage = (page: number, limit: number | string) => {
    dispatch({
      type: 'CHANGE_PAGE',
      payload: {
        page,
        limit,
      },
    });
    changeUrl({
      page,
      limit,
    });
  };

  const loadAccounts = (
    accounts: Array<{
      name: string;
      account: string;
    }>
  ) =>
    dispatch({
      type: 'LOAD_ACCOUNTS',
      payload: {
        accounts,
      },
    });

  const filterBySelectedRange = ({
    startDate,
    endDate,
  }: {
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
  }) => {
    dispatch({
      type: 'FILTER_BY_DATE',
      payload: {
        startDate,
        endDate,
      },
    });
    changeUrl({
      startDate,
      endDate,
      page: 1,
      limit: state.filterBy.limit,
    });
  };

  const filterByEvents = (events: Array<string>) => {
    dispatch({
      type: 'FILTER_BY_EVENTS',
      payload: {
        events,
      },
    });
    changeUrl({
      eventNames: events.join(','),
      page: 1,
      limit: state.filterBy.limit,
    });
  };

  const filterByTokens = (tokenTypes: Array<string>) => {
    dispatch({
      type: 'FILTER_BY_TOKENS',
      payload: {
        tokenTypes,
      },
    });
    changeUrl({
      tokenTypes: tokenTypes.join(','),
      page: 1,
      limit: state.filterBy.limit,
    });
  };

  // selectors

  // clear ws socket connection when reload page or close tab
  const ethAddress = getAccountAddress();
  useEffect(() => {
    if (ethAddress && user?.attributes) {
      if (!state.accountsNames[ethAddress]) {
        dispatch({
          type: 'LOAD_ACCOUNTS',
          payload: {
            accounts: [
              {
                account: ethAddress,
                name: user?.attributes['name'],
              },
            ],
          },
        });
      }
    }
  }, [dispatch, ethAddress, state.accountsNames, user]);

  useEffect(() => {
    // fetch(
    //   `${WEB_INDEXER_API}/${
    //     blockchainView ? "tx-types" : "tx-types-affect-balance"
    //   }`,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    //   .then((resp: Response) => resp.json())
    //   .then((values) => setTxTypes(values))
    //   .catch((err) => console.log(err));
  }, [blockchainView]);

  // Init data, connect web3 and fetch contract data
  useEffect(() => {
    // Initiates state and get contract data, if WEB settings already loaded
    if (settings) {
      console.time('getContract');
      // getContract();
      console.timeEnd('getContract');
    }
  }, [settings]);

  const {
    filterBy: { limit },
    contractJson,
  } = state;

  return {
    ...state,
    actions: {
      loadAccounts,
      filterBySelectedRange,
      filterByEvents,
      filterByTokens,
      changePage,
    },
    selectors: {
      isReady: () => web3HttpRef?.current && deployedContractRef?.current && contractJson?.addr,
      projectSuffix: () => {
        return process.env.WEB_COMPANY_NAME !== 'BRAND2' ? 'Asset' : '';
      },
      // NOTE: only BRAND1 has unit value
      hasUnitValue: () => process.env.WEB_COMPANY_NAME !== 'BRAND2',
    },
    deployedContract: deployedContractRef.current,
    web3: web3HttpRef.current,
    wsWeb3: web3WsRef.current,
    abiDecoder,
    limit,
    txTypes,
  };
}

export const Contract = createContainer(useContract);
