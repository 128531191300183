/* eslint-disable jsx-a11y/alt-text */

/* eslint-disable react/jsx-no-target-blank */
import Container from 'components/InvestorCommon/Box/Box';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { LOCAL_STORAGE, PERMISSIONS } from 'utils/constant';
import { IMAGES } from 'utils/images';

interface ISidebarMobile {
  title: string;
  link: string;
  icon?: any;
}

const FOOTER_ITEM_WITHOUTCORP: ISidebarMobile[] = [
  {
    title: 'Home',
    link: '/investors',
    icon: IMAGES.sidebar.home,
  },
  {
    title: 'Wallet',
    link: '/investors/wallet',
    icon: IMAGES.sidebar.wallet,
  },
  {
    title: 'Platforms',
    link: '/investors/primary-listing',
    icon: IMAGES.sidebar.platforms,
  },
  // {
  //   title: 'Help Center',
  //   link: '/investors/help-center',
  //   icon: IMAGES.sidebar.helpCenter,
  // },
  {
    title: 'Settings',
    link: '/investors/individual/investorInfo',
    icon: IMAGES.sidebar.settings,
  },
];

const FOOTER_ITEM_WITHCORP: ISidebarMobile[] = [
  {
    title: 'Home',
    link: '/investors',
    icon: IMAGES.sidebar.home,
  },
  {
    title: 'Wallet',
    link: '/investors/wallet',
    icon: IMAGES.sidebar.wallet,
  },
  {
    title: 'Platforms',
    link: '/investors/primary-listing',
    icon: IMAGES.sidebar.platforms,
  },
  // {
  //   title: 'Help Center',
  //   link: '/investors/help-center',
  //   icon: IMAGES.sidebar.helpCenter,
  // },
  {
    title: 'Settings',
    link: '/investors/coporate/companyInfo',
    icon: IMAGES.sidebar.settings,
  },
];

const FOOTER_ITEM_ISSUER: ISidebarMobile[] = [
  {
    title: 'Home',
    link: '/investors',
    icon: IMAGES.sidebar.home,
  },
  {
    title: 'My Project',
    link: '/issuers/my-project',
    icon: IMAGES.sidebar.project,
  },
  {
    title: 'Platforms',
    link: '/investors/primary-listing',
    icon: IMAGES.sidebar.platforms,
  },
  {
    title: 'Settings',
    link: '/issuer/issuerInfo',
    icon: IMAGES.sidebar.settings,
  },
];

const FooterItem: FC<ISidebarMobile> = (props) => {
  return (
    <div className="py-2">
      <Link to={props.link}>
        <div className="flex flex-col items-center">
          <img src={props.icon} className="px-3" />
          <label htmlFor="" className="text-xs text-white inline-flex">
            {props.title}
          </label>
        </div>
      </Link>
    </div>
  );
};

const FooterMobile: FC = () => {
  const [categoryCurrent, setCategoryCurrent] = useState('');
  const isCategoryChoice = categoryCurrent || 'Home';
  const permissions = localStorage.getItem(LOCAL_STORAGE.user)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.user) || '')?.permissions
    : [];

  const FOOTER_ITEM = permissions.includes(PERMISSIONS.WEB_INVESTOR_CORP_MANAGE)
    ? FOOTER_ITEM_WITHCORP
    : permissions.includes(PERMISSIONS.WEB_ISSUER_AUTHORIZE) ? FOOTER_ITEM_ISSUER
    : FOOTER_ITEM_WITHOUTCORP;
  return (
    <div style={{ maxWidth: '100%' }}>
      <Container
        bgColor="#2E3192"
        className="grid grid-cols-5 mt-5 gap-0 px-2 pt-2 pb-6 justify-between items-center fixed bottom-0 w-full"
        style={{ maxWidth: '100%', zIndex: 100 }}
      >
        {FOOTER_ITEM.map((item) => (
          <button
            onClick={() => {
              setCategoryCurrent(item?.title);
            }}
            key={item?.title}
            className={`${item?.title === isCategoryChoice ? 'bg-gray-600 rounded-md items-center w-full' : ''} `}
          >
            <FooterItem title={item.title} icon={item.icon} link={item.link}></FooterItem>
          </button>
        ))}
        <button>
          <div className="py-2">
            <div className="flex flex-col items-center">
              <img src={IMAGES.sidebar.helpCenter} className="px-3" />
              <a href={`${process.env.WEB_URL_HELP_CENTER}`} target="_blank" style={{ color: '#FFFFFF' }}>
                <label htmlFor="" className="text-xs text-white inline-flex">
                  {'Help Center'}
                </label>
              </a>
            </div>
          </div>
        </button>
      </Container>
      <div className="w-full h-20"></div>
    </div>
  );
};

export default FooterMobile;
