import S from './styled';
import { ITitleProps } from './type';
import React from 'react';

const Title: React.FC<ITitleProps> = ({
  color,
  children,
  height,
  onClick,
  radius,
  width,
  className,
  text,
  size,
  disabled,
  level,
  weight,
  ...props
}) => {
  return (
    <S.Title
      onClick={onClick}
      text={text}
      className={`${className} inline-flex items-center`}
      size={size}
      disabled={disabled}
      level={level}
      color={color}
      weight={weight}
      {...props}
    >
      {children}
    </S.Title>
  );
};

export default Title;
