import { ContainerItem, DateTimeItem, InfoItem, TitleItem } from './Styled';
import { userViewNotification } from 'api/Issuer/request';
import moment from 'moment';
import { useMutation } from 'react-query';
import { RefetchOptions } from 'react-query/types/core/query';

interface Props {
  title: string;
  imgUrl: string;
  dateTime: string;
  link: string;
  status: boolean;
  notiId: number;
  refetch: (options?: RefetchOptions | undefined) => Promise<any>;
  setShowNotification: () => void;
}

function Item(props: Props) {
  const { title = '', dateTime = '', link, status, notiId, refetch, setShowNotification } = props;
  const noti_body = title.split('<HF5>');
  const handleDateNotification = (noti_body: string[]) => {
    if (noti_body.length < 3) {
      return noti_body[0];
    } else {
      const dateString = noti_body[2];
      const dateObj = new Date(dateString);
      const formattedDate = dateObj.toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      });
      return noti_body[1] + formattedDate + noti_body[3];
    }
  };
  const [userClickNoti] = useMutation(userViewNotification, {
    onSuccess: (data) => {
      refetch();
    },
    onError: (error) => {
      return;
    },
  });
  const handleClickNoti = () => {
    userClickNoti({ id: String(notiId) });
    setShowNotification();
  };
  return (
    <a href={link}>
      <ContainerItem onClick={handleClickNoti}>
        <InfoItem>
          <TitleItem style={{ opacity: status ? 1 : 0.5 }}>{handleDateNotification(noti_body)}</TitleItem>
          <DateTimeItem style={{ opacity: status ? 1 : 0.5 }}>{moment(dateTime).format('MM-DD-YYYY')}</DateTimeItem>
        </InfoItem>
      </ContainerItem>
    </a>
  );
}

export default Item;
