import {API, graphqlOperation } from '@aws-amplify/api'

export const config = {
    "aws_project_region": process.env.WEB_AWS_PROJECT_REGION,
    "aws_appsync_graphqlEndpoint": process.env.WEB_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    "aws_appsync_region": process.env.WEB_AWS_APPSYNC_REGION,
    "aws_appsync_authenticationType": process.env.WEB_AWS_APPSYNC_AUTHENTICATION_TYPE,
    "aws_appsync_apiKey": process.env.WEB_AWS_APPSYNC_API_KEY
}

export const subscribeDoc = /* GraphQL */ `
    subscription Subscribe($name: String!) {
        subscribe(name: $name) {
            data
            name
        }
    }
`

export const publishDoc = /* GraphQL */ `
    mutation Publish($data: AWSJSON!, $name: String!) {
        publish(data: $data, name: $name) {
            data
            name
        }
    }
`

export async function publish(name, data) {
    return await API.graphql(graphqlOperation(publishDoc, { name, data }))
}
export function subscribe(name, next, error) {
    return API.graphql(graphqlOperation(subscribeDoc, { name })).subscribe({
        next: ({ provider, value }) => {
            next(value.data.subscribe, provider, value)
        },
        error: error || console.log,
    })
}

